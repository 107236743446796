import React from 'react';
import { withNamespaces } from 'react-i18next';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

const HowToGetThere = (props) => {
  const { t, classes } = props;

  let howToGetThere = [];

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb
          firstHalf={`${t('More')} >`}
          secondHalf={t('How_to_get_title_new')}
        />
        <CenteredTitle firstHalf={t('How_to_get_title_new')} secondHalf={''} />
      </div>
      {howToGetThere.length == 0 ? (
        <>
          <div className="static_page">
            <section className="row">
              <article className="container-fluid opening_hours">
                {/* <h3>
                  {t(
                   'how_to_get_title',
                  )}
                </h3> */}
                <p className="ml-2 mb-2">{t('how_to_get_cinepol_data_para1')}</p>
                <p className="ml-2 mb-2">{t('how_to_get_cinepol_data_para2')}</p>
                <p className="ml-2 mb-2">{t('how_to_get_cinepol_data_para3')}</p>
                <p className="ml-2 mb-2">{t('how_to_get_cinepol_data_para4')}</p>
                <p className="ml-2 mb-2">{t('how_to_get_cinepol_data_para5')}</p>
              </article>
            </section>
          </div>
        </>
      ) : (
        <div className="static_page">
          <section className="row">
            <article className="col-xl-10 mx-auto">
              {/* <p>
                You have preferably completed an apprenticeship in the hospitality
                industry or have good knowledge and professional experience.
              </p>
              <p>
                You love customer contact and are interested in working together
                with a motivated team to give our guests an unforgettable cinema
                experience.
              </p>

              <p className="static_page_highlight my-3 my-xl-4">
                Your main tasks include:
              </p> */}

              {/* <ul className="pl-4">
                <li>
                  Independent operation of bar and kiosk with a comprehensive offer
                </li>
                <li>Preparation of small dishes</li>
                <li>Cinema ticket sale</li>
                <li>Issuing telephone information (German/English)</li>
                <li>Catering at events</li>
              </ul> */}
            </article>
          </section>
        </div>
      )}
    </>
  );
};

export default withNamespaces()(HowToGetThere);
