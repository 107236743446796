// import React from 'react';
import { withNamespaces } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
// import YouTubeImage from '@assets/pictures/youtube_image.png';
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
// import useIsMobile from '../../customHooks/useIsMobile';
import { useHistory, useLocation } from 'react-router-dom';
import useIsMobile from '@src/customHooks/useIsMobile';
import moment from 'moment';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';

// Components

import Slider from 'react-slick';
import SpinnerCompo from '../components/partials/Spinner';
import seatsImg from '@assets/pictures/untitled-12.jpg';
import seatsImg1 from '@assets/pictures/untitled-22.jpg';
import seatsImg2 from '@assets/pictures/untitled-41.jpg';
import MovieService from '@apiService/MovieService';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

import TrailerIcon from '@assets/pictures/svgs/play_new_exp.svg';
import ModalVideo from 'react-modal-video';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { BASEURL } from '../config/index';
import { getSeatIconUrl } from '@src/helper';
const Offers = ({ t }) => {
  const { t1, banners, isLoading, showOnly = null } = t;
  const isMobile = useIsMobile();
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  const { pathname } = useLocation();
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [OfferPagesData, setOfferPagesData] = useState([]);

  const [offerdiv, setofferdiv] = useState(false);

  const OfferPagesDataFunc = () => async (dispatch) => {
    try {
      // dispatch({ type: ADVERTISE_PDF_DATA });
      // const { data } = await CinemaService.GetAdvertisePdfData();
      const response = await MovieService.GetaActivePromotionAndOffersNew();
      const { data } = response;
      if (data?.status) {
        setOfferPagesData(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(OfferPagesDataFunc());
  }, []);

  let banner_arr = [];
  OfferPagesData?.map((x) => {
    return banner_arr.push(x?.offer_banner_image);
  });

  let filtered_bannerarr = banner_arr?.filter((y) => y);
  // let dummyPosterImg = localStorage.getItem('placeholder_image');

  // console.log(dummyPosterImg, 'dummyPosterImg');
  let poster_banner = [];
  OfferPagesData?.map((x) => {
    return poster_banner.push(x?.offer_poster_image);
  });
  // console.log(poster_banner, 'poster_banner');
  // console.log(filtered_bannerarr, 'filtered_bannerarr');
  // console.log(OfferPagesData, 'OfferPagesData');

  const [swiping, setSwiping] = useState(false);
  const bannerSlider = {
    pauseOnFocus: true,
    onSwipe: () => setSwiping(true),
    arrows: false,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="align_new offer_main_wrapper">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            // secondHalf={`${t('Experience')}`}
            secondHalf={`${'Offers'}`}
          />
          <CenteredTitle
            //   firstHalf={`${t('Privacy Policy')}`}
            firstHalf={`${'Offers'}`}
            secondHalf={''}
          />
        </div>
      </div>
      {/* Dynamic Design Start*/}
      {false && (
        <div className="align_new offer_main_wrapper">
          <div className="left_right_align">
            {/* <BreadCrumb
            firstHalf={`${t('More')} >`}
            // secondHalf={`${t('Experience')}`}
            secondHalf={`${('Experience')}`}
          /> */}
            <CenteredTitle
              //   firstHalf={`${t('Privacy Policy')}`}
              firstHalf={`${'Offers'}`}
              secondHalf={''}
            />
          </div>
          {/* <ModalVideo
          autoplay
          channel={videoChannel}
          isOpen={isOpen}
          videoId={currentVideoId}
          onClose={() => setOpen(false)}
        /> */}

          <Slider
            {...bannerSlider}
            className="home_banner"
            style={{ marginBottom: '0px', marginLeft: '0px !important' }}
          >
            {/* {filtered_bannerarr.length == 0 && (
            <React.Suspense
              fallback={
                <div className="row">
                  <div className="col-12 text-center">
                    <SpinnerCompo />
                  </div>
                </div>
              }
            />
          )} */}
            {filtered_bannerarr.length > 0 &&
              filtered_bannerarr?.map((x) => {
                return (
                  <>
                    {/* <span>{x.pp_title}</span> */}
                    {x && (
                      <img
                        style={{ cursor: 'pointer' }}
                        src={getSeatIconUrl(x)}
                        alt={getSeatIconUrl(x)}
                        className="img-fluid m-auto"
                        // href={x.pp_image_url}
                      />
                    )}
                  </>
                );
              })}
          </Slider>

          <section className="container-fluid container-xl mt-3">
            <h5 className="font-weight-bold">Cinema Offer !!!</h5>
            <p>
              To get our best offers all you need to do is save this page or
              download the Brij Cinemas application and stay tuned.
            </p>
          </section>
          <section className="container-fluid container-xl mt-3 offer_poster_wrapper">
            <div className="mt-4">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey={1}
                className="custom_nav_link"
              >
                <Row>
                  <Col sm={12} lg={12} xl={12} className="slider_section">
                    <Nav variant="pills" className="custom_overflow_location ">
                      {OfferPagesData?.map((x, index) => (
                        <Nav.Item>
                          <Nav.Link eventKey={x?.os_id}>
                            <div className="">
                              <img
                                style={{ cursor: 'pointer' }}
                                src={getSeatIconUrl(x?.offer_poster_image)}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = dummyPosterStatic;
                                }}
                                alt={getSeatIconUrl(x?.offer_poster_image)}
                                className="custom-img-fluid m-auto"
                                // href={x.pp_image_url}
                              />

                              {/* <h5>{cinema.cinema_name}</h5> */}
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={12} lg={12} xl={12} className="mt-3">
                    <Tab.Content>
                      {OfferPagesData?.map((y, index) => (
                        <Tab.Pane eventKey={y?.os_id}>
                          <Row className="m-auto">
                            <Col
                              sm={12}
                              lg={9}
                              xl={7}
                              className="d-flex flex-column align-items-left justify-content-start text-left custom_border_radius_left"
                              style={{ backgroundColor: '#e5e6e7', color: '#000' }}
                            >
                              <p className="tnc_name_heading my-3">
                                {y?.offer_title}
                              </p>
                              {/* <p className="tnc_name_heading my-3">
                              Terms & Condition
                            </p> */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: y?.offer_description
                                    ? y?.offer_description
                                    : '',
                                }}
                                className="offer_description_wrapper"
                              ></div>
                              {y?.offer_redeem_at_boxoffice == 'Y' &&
                                y?.offer_redeem_at_online == 'N' &&
                                !y?.offer_description && (
                                  <div className="offer_description_wrapper">
                                    <p>
                                      This Offer is Applicable Only on BoxOffice
                                    </p>
                                  </div>
                                )}
                            </Col>

                            {!isMobile && (
                              <Col
                                sm={12}
                                lg={3}
                                xl={2}
                                className="d-flex flex-column align-items-center justify-content-center text-center custom_border_radius_right"
                                style={{
                                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                  backgroundColor: !y?.offer_poster_image
                                    ? 'rgb(228,228,228)'
                                    : 'rgb(229, 230, 231)',
                                  padding: '0px',
                                }}
                              >
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={getSeatIconUrl(y?.offer_poster_image)}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = dummyPosterStatic;
                                  }}
                                  className="img-fluid custom_border_radius_right"

                                  // href={y.pp_image_url}
                                />
                              </Col>
                            )}
                          </Row>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </section>
        </div>
      )}
      {/* Dynamic Design End*/}

      <div
        className="container-fluid static_page"
        style={{ backgroundColor: 'var(--dark-9)' }}
      >
        <section className="row">
          <article className="col-xl-10 mx-auto">
            <p className="my-4 my-xl-5"> {t('rent_our_space_data_para1')}</p>
            <p className="mb-2 text-primary data-title-size">
              {t('rent_our_space_subtitle_program')}
            </p>
            <p className="mb-4 mb-xl-5">{t('rent_our_space_data_para2')}</p>
            <p className="mb-2 text-primary data-title-size">
              {t('rent_our_space_subtitle_booking')}
            </p>
            <p className="mb-4 mb-xl-5">{t('rent_our_space_data_para3')}</p>
            {/* <p className="mt-4 mt-xl-5">You Cinema Address</p> */}
            {/* <p className="mt-4 mt-xl-5">
              Kino Stüssihof
            </p>
            <p>
              Stüssihofstatt 13
            </p>
            <p>
              CH-8001 Zürich
            </p> */}
            {/* <p className="text-primary">Yourcinema@yourcinema.com</p> */}
            {/* <p className="text-primary">
              stüssihof.ch
            </p> */}
          </article>
        </section>
      </div>
    </>
  );
};
export default withNamespaces()(Offers);
