import React, { useEffect, useState, useRef, useContext } from 'react';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
import { useParams } from 'react-router';
import { withNamespaces } from 'react-i18next';
import close from '@assets/pictures/svgs/close.svg';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { currencyFormatter } from '../../../helper/currencyFormatter';
import { getLangSpecificAttribute } from '@helper/languages';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { debounce, throttle } from 'lodash';
import { setPrivateScreeningPayload } from '../../../store/seatLayout/seatLayoutActions';
import { movieLanguagesWithSubs } from '@helper/formatting';
import Spinner from '@components/partials/Spinner';

const checkValidSelection = ({ fnb, slab, isFnbCompulsory, total }) => {
  if (total <= 0) return false;
  if (!slab?.quantity) return false;
  if (isFnbCompulsory && !fnb?.quantity) return false;

  return true;
};

const getFnbTotal = ({ fnb, isFnbCompulsory, customSeatCount }) => {
  if (!isFnbCompulsory && !fnb?.quantity) return 0;

  if (customSeatCount > 0) return customSeatCount * (fnb?.price ?? 0);

  return (fnb?.quantity ?? 0) * (fnb?.price ?? 0);
};
const getFnbTotalNet = ({ fnb, isFnbCompulsory, customSeatCount }) => {
  if (!isFnbCompulsory && !fnb?.quantity) return 0;

  if (customSeatCount > 0) return customSeatCount * (fnb?.net_price ?? 0);

  return (fnb?.quantity ?? 0) * (fnb?.net_price ?? 0);
};

const getSlabTotal = ({ slab, isTicketSlab, customSeatCount }) => {
  if (!isTicketSlab && !slab?.quantity) return 0;

  if (customSeatCount > 0) return customSeatCount * (slab?.price ?? 0);

  return (slab?.quantity ?? 0) * (slab?.price ?? 0);
};
const getSlabTotalNet = ({ slab, isTicketSlab, customSeatCount }) => {
  if (!isTicketSlab && !slab?.quantity) return 0;

  if (customSeatCount > 0) return customSeatCount * (slab?.net_price ?? 0);

  return (slab?.quantity ?? 0) * (slab?.net_price ?? 0);
};

const SpecialInstructionsPopup = ({
  t,
  showPrivateScreenPopup,
  onConfirmPrivateScreeningPopup,
  closeFunc,
}) => {
  const [specialRequest, setSpecialRequest] = useState('');
  const [isTotalValid, setTotalIsValid] = useState(false);
  const { category_id } = useParams();
  const scheduleDetailsLoading = useSelector(
    (state) => state.movies.scheduleDetailsLoading,
  );

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      className="seat-type-popup"
      centered
      onHide={() => closeFunc(false)}
      show={showPrivateScreenPopup}
    >
      {!!scheduleDetailsLoading ? (
        <section className="new_pop_lay">
          <div className="row">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        </section>
      ) : (
        <section className="new_pop_lay">
          <img
            src={close}
            className="close-btn-main"
            onClick={() => closeFunc(false)}
            alt=""
          />
          {/* <section className="row">
          <div className="col-12">
            <article className="alert alert-warning">
              It will book entire hall
            </article>
          </div>
        </section> */}
          <section className="row">
            <div className="col-12 payment-booking-details">
              <PaymentBookingDetails
                category_id={category_id}
                t={t}
                setTotalIsValid={setTotalIsValid}
              />
            </div>
          </section>
          <>
            <div className="last_sec_pop">
              <div className="question">
                <p className="mb-2 text_pop">
                  {t('Have a special request or instruction')}
                </p>
                <input
                  type="text"
                  className="answer"
                  placeholder={t('Type Here...')}
                  value={specialRequest}
                  onChange={(e) => setSpecialRequest(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="px-3 w-100"></div>
            </div>

            <div className="d-flex flex-column flex-md-row align-items-center flex-wrap mb-4">
              <button
                disabled={!isTotalValid}
                className={`btn-main-pop btn-main-lg mx-auto ${
                  !isTotalValid && 'inactive'
                }`}
                onClick={() => onConfirmPrivateScreeningPopup(specialRequest)}
              >
                {t('Confirm')}
              </button>
            </div>
          </>
        </section>
      )}
    </Modal>
  );
};

export default withNamespaces()(SpecialInstructionsPopup);

//Start Payment Booking Details
const PaymentBookingDetails = ({ category_id, t, setTotalIsValid }) => {
  let dispatch = useDispatch();
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const booking_type = useSelector((state) => state.movies.booking_type);
  let userSelection = useSelector((state) => state.movies.userSelection);
  const appliedVoucher = useSelector((state) => state.promotions.applied_voucher);
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const selected_seats = useSelector((state) => state.seatLayout.seats_selected);
  const screeningTypes = useSelector((state) => state.movies.screeningTypes);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;
  const scheduleDetailsLoading = useSelector(
    (state) => state.movies.scheduleDetailsLoading,
  );

  const { lang_id, lang_name, iso_2 } = selected_language;
  const [seats, setSeats] = useState(0);
  const screen = screeningTypes.find((screen) => screen.booking_type_id === 3);

  let [total, setTotal] = useState(0);
  let [slab, setSlab] = useState(null);
  let [fnb, setFnb] = useState(null);
  let [addon, setAddon] = useState(null);

  let [privateRateCardDetails] = screen?.rate_card_array;

  let isTicketSlab = privateRateCardDetails?.isTicketSlab === 'Y';
  let isFnbCompulsory = privateRateCardDetails?.isFnbCompulsory === 'Y';

  const updateSlab = (slab) => {
    setSlab(slab);
  };
  const updateFnb = (fnbToSet, e) => {
    setFnb(
      fnb && fnb?.fnb_private_id === fnbToSet?.fnb_private_id ? null : fnbToSet,
    );
  };
  const updateAddon = (e, addonToSet) => {
    // setAddon(e.target.checked ? addonToSet : null);
    setAddon(
      addon && addon.fnb_private_id === addonToSet.fnb_private_id
        ? null
        : addonToSet,
    );
  };

  const updateCustomSeatCount = (seatCount, type = 'add') => {
    if (
      (type === 'add' &&
        (seats + seatCount > screen.screen_total_seats ||
          seats + seatCount <= 0)) ||
      (type !== 'add' && (seatCount >= screen.screen_total_seats || seatCount <= 0))
    ) {
      console.log('updateCustomSeatCount', {
        type,
        seats,
        seatCount,
        screen_total_seats: screen.screen_total_seats,
      });
      return;
    }

    // get neatest highest slab for custom seat count tobe updated slab to it
    let slabsAvail = privateRateCardDetails.rateCardArray
      .filter((s) => s.quantity >= (type === 'add' ? seats + seatCount : seatCount))
      .sort((a, b) => a.quantity - b.quantity);

    // if not nearest highest slot get slot with highest quantity available
    if (!slabsAvail.length)
      slabsAvail = privateRateCardDetails.rateCardArray.sort(
        (a, b) => b.quantity - a.quantity,
      );

    updateSlab(slabsAvail[0]);
    setSeats(type === 'add' ? seats + seatCount : seatCount);
  };

  useEffect(() => {
    if (
      privateRateCardDetails?.rateCardArray?.length === 1 &&
      privateRateCardDetails?.isTicketSlab === 'Y'
    )
      updateSlab(privateRateCardDetails?.rateCardArray[0]);
  }, [privateRateCardDetails.isTicketSlab, privateRateCardDetails.rateCardArray]);

  useEffect(() => {
    let fnbFiltered = null;

    // fnbOptions form data pricing for current selected fnb
    let fnbOptions = privateRateCardDetails.fnbDataPricing
      .filter((f) => fnb?.item_id === f?.item_id)
      .sort((a, b) => a.quantity - b.quantity);

    // fnb options which are 1:1 to ticketSlab by quantity value
    let fnbOptionsBySlab = fnbOptions.filter((f) => slab?.quantity === f?.quantity);

    // if fnb/ticketSlab pair with quantity exist
    if (fnbOptionsBySlab.length) {
      let [fnbMapped] = fnbOptionsBySlab; // fnbOptions
      fnbFiltered = fnbMapped;

      console.log({
        fnbPriceForTotal: (fnbMapped.price ?? 0) * (fnbMapped.quantity ?? 0),
        price: fnbMapped.price,
        quantity: fnbMapped.quantity,
        fnbMapped,
      });
    } else {
      // here because no fnb items mapped to ticket slab by quantity
      // then for each fnbDataPricing check nearest lower fnbDataPricing object
      let nearestFnbOption = null;
      fnbOptions.forEach((f, i) => {
        if (f.quantity <= slab?.quantity) nearestFnbOption = f;
      });

      fnbFiltered = nearestFnbOption ?? fnbOptions[0];

      console.log({
        fnbPriceForTotal:
          (nearestFnbOption?.price ?? 0) * (nearestFnbOption?.quantity ?? 0),
        price: nearestFnbOption?.price,
        quantity: nearestFnbOption?.quantity,
        nearestFnbOption,
      });
    }

    console.log('fnbOptions', fnbOptions);

    let total =
      (addon?.price ?? 0) +
      getSlabTotal({
        slab,
        isTicketSlab,
        customSeatCount: seats > 0 ? seats : slab?.quantity,
      }) +
      getFnbTotal({
        fnb: fnbFiltered,
        isFnbCompulsory,
        customSeatCount: seats > 0 ? seats : slab?.quantity,
      });
    let total_net =
      (addon?.net_price ?? 0) +
      getSlabTotalNet({
        slab,
        isTicketSlab,
        customSeatCount: seats > 0 ? seats : slab?.quantity,
      }) +
      getFnbTotalNet({
        fnb: fnbFiltered,
        isFnbCompulsory,
        customSeatCount: seats > 0 ? seats : slab?.quantity,
      });

    setTotalIsValid(
      checkValidSelection({ fnb: fnbFiltered, slab, isFnbCompulsory, total }),
    );
    setTotal(total);

    console.log({ total, total_net });

    dispatch(
      setPrivateScreeningPayload({
        customSeatCount: seats > 0 ? seats : slab?.quantity,
        isTicketSlab: privateRateCardDetails.isTicketSlab,
        isFnbCompulsory: privateRateCardDetails.isFnbCompulsory,
        total,
        total_net,
        slab,
        fnb: fnbFiltered,
        addon,
      }),
    );
  }, [
    seats,
    addon,
    fnb,
    privateRateCardDetails.isFnbCompulsory,
    privateRateCardDetails.isTicketSlab,
    // payload,
    privateRateCardDetails.fnbDataPricing,
    setTotalIsValid,
    slab,
    dispatch,
    isTicketSlab,
  ]);

  return (
    <>
      <div className="header">
        <div className="left text-center">
          <img
            src={
              getLangSpecificAttribute(
                scheduleDetails?.languageSpecificImages,
                lang_id,
                'artwork',
              ) || dummyPoster
            }
            alt="Movie Img"
            className="movie-img"
            onError={({ currentTarget }) => {
              currentTarget.onerror = () => {}; // prevents looping
              currentTarget.src = dummyPosterStatic;
            }}
          />
        </div>
        <div className="right">
          <div className="align_popup_pvt_mob">
            <p className="mb-1 final_heading_private ">{t('Private Cinema')}</p>
            <h6 className="title ">
              {getLangSpecificAttribute(
                scheduleDetails?.altMovieContent,
                lang_id,
                'mc_title',
              ) ||
                scheduleDetails?.movie_title ||
                scheduleDetails?.original_title_of_movie}

              <p className="year_private">
                {moment(scheduleDetails?.mrrdr_release_date).format('(YYYY)')}
              </p>
            </h6>
            <div className="private_popup_tags">
              <button className="private_popup_btn">
                {movieLanguagesWithSubs(
                  scheduleDetails?.lang_name,
                  scheduleDetails?.sub_1_iso_2,
                  scheduleDetails?.sub_2_iso_2,
                )}
              </button>
              <button className="private_popup_btn ">
                {scheduleDetails?.mrrdr_runtime} {t('common.mins')}{' '}
              </button>
              <button className="private_popup_btn">
                {scheduleDetails?.rating}
              </button>
            </div>
            <div className="features">
              {/*<p className="mb-1">
                {scheduleDetails?.version_name || t(scheduleDetails?.lang_name) || scheduleDetails?.lang_name} {scheduleDetails?.mf_name}
          </p>*/}
              {/*<p className="mb-1">
                {scheduleDetails?.mrrdr_runtime} {t("common.mins")}{" "}
        </p>*/}
              <div
                style={{ textTransform: 'uppercase' }}
                className="private_pop_spacing"
              >
                <p
                  style={{ color: 'var(--yellow-shade)' }}
                  className="final_heading_private"
                >
                  DATE & TIME
                </p>
                <p className="mb-1 title" style={{ fontweight: '400' }}>
                  {moment(scheduleDetails?.ss_actual_start_date_time).format('ll')}{' '}
                  |{' '}
                  {moment(scheduleDetails?.ss_start_show_time, 'HH:mm:ss').format(
                    'HH:mm',
                  )}
                </p>
              </div>
              <div style={{ textTransform: 'uppercase' }}>
                <p className="final_heading_private mb-1"> CINEMA</p>
                <p className="mb-1 title" style={{ fontweight: '400' }}>
                  {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}
                </p>
              </div>
              {/* <div className="movie-rating">
                <p className="fs-13">
                  {t("Rating")}&nbsp;:&nbsp;
                  <span className="value small-font">
                    {scheduleDetails?.rating}
                  </span>
                </p>
                </div>*/}
            </div>
          </div>
        </div>
      </div>
      {/* End Header */}

      <div className="middle">
        <div>
          <p>
            {/* {t("Cinema")}:{" "} */}
            {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
          </p>
          <p>
            {/* {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address} */}
            {/* {scheduleDetails?.cine_location} */}
          </p>
        </div>
        <div>
          <p>
            {/* {t("Date")} & {t("Time")}:{" "} */}
            {/* <span className="text-primary fs-10 font-italic">(Change)</span> */}
          </p>
          {/* <p>
            {moment(scheduleDetails?.ss_actual_start_date_time).format("ll")} |{" "}
            {moment(scheduleDetails?.ss_start_show_time, "HH:mm:ss").format(
              "HH:mm"
            )}
          </p> */}
        </div>

        <div className="align-items-center">
          <div className="middle_private_pop">
            <div style={{ display: 'flex' }}>
              <p className="mr-2" style={{ color: 'white', fontWeight: 'bold' }}>
                {t('Select Seats Size')}
              </p>
              <p style={{ color: 'var(--yellow-shade)' }}>
                {`(${screen?.screen_total_seats} ${t('Seating Capacity')})`}
              </p>
            </div>
            <div>
              <p>{t('Amount')}</p>
            </div>
          </div>
          {isTicketSlab ? (
            <>
              {/*<div className="d-flex align-items-center ml-2 flex-wrap">
              {privateRateCardDetails.rateCardArray.map((s, index) => (
                <button
                  key={s.rcg_c_id}
                  className={`select-btn ${
                    slab && slab.rcg_c_id === s.rcg_c_id && "selected"
                  }`}
                  onClick={() => {
                    updateSlab(s);
                  }}
                >
                  {s.quantity}
                </button>
              ))}
            </div>*/}
              {privateRateCardDetails.rateCardArray.map((s, index) => (
                <div className="all_btn_pop ">
                  <div className="checkbox-row" key={s?.rcg_c_id}>
                    <label className="custom-check">
                      <input
                        type="checkbox"
                        name="slabCheckbox"
                        id={s?.rcg_c_id}
                        checked={slab && slab?.rcg_c_id === s?.rcg_c_id}
                        onClick={() => {
                          updateSlab(s);
                        }}
                      />
                      <span />
                    </label>
                    <p className="mx-2 desktop_bottom">{s?.quantity}</p>
                  </div>

                  <div>
                    <p className="font-weight-bold pop_total">
                      {!!slab && slab?.rcg_c_id === s?.rcg_c_id ? (
                        <>
                          {curr_code}{' '}
                          {currencyFormatter(slab?.price * slab?.quantity || 0)}
                        </>
                      ) : (
                        ''
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <aside
              className="d-flex align-items-center justify-content-between"
              className="all_btn_pop"
            >
              <div className="d-flex modifier-btn">
                <span
                  className={seats <= 1 ? 'inactive' : ''}
                  onClick={() => {
                    if (seats <= 1) return;
                    updateCustomSeatCount(-1, 'add');
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  {`-`}
                </span>

                <span className="">
                    <input
                    className="text-color"
                    type="number"
                    placeholder={t('Number of seats')}
                    value={seats}
                    max={screen?.screen_total_seats}
                    onChange={(e) => {
                      updateCustomSeatCount(parseInt(e.target.value), 'set');
                    }}
                  />
                </span>

                <span
                  className={seats >= screen?.screen_total_seats ? 'inactive' : ''}
                  onClick={() => {
                    if (seats >= screen?.screen_total_seats) return;
                    updateCustomSeatCount(1, 'add');
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                  }}
                >
                  {`+`}
                </span>
              </div>

              <div>
                <p className="font-weight-bold pop_total">
                  {seats > 0 && (
                    <>
                      {curr_code} {currencyFormatter(seats * slab?.price || 0)}
                    </>
                  )}
                </p>
              </div>
            </aside>
          )}
        </div>

        {privateRateCardDetails?.fnbListing.length > 0 && (
          <div className="add_private_pop">
            <p className="" style={{ color: 'white', fontWeight: 'bold' }}>
              {t('Combo Selection')}
            </p>
            <p style={{ color: 'var(--yellow-shade)' }} className="ml-2">
              ({t('Select only one')})
            </p>
          </div>
        )}

        {privateRateCardDetails.fnbListing.map((f, i) => (
          <div className="all_btn_pop">
            <div className="checkbox-row" key={i}>
              <label className="custom-check">
                <input
                  type="radio"
                  name="{f.item_name}"
                  id={f.item_name}
                  checked={fnb?.fnb_private_id === f.fnb_private_id}
                  onClick={(e) => updateFnb(f, e)}
                />
                <span />
              </label>
              <p className="mx-2 desktop_bottom">{`
                ${f.item_name} 
                ${
                  seats > 0
                    ? `x(${seats})`
                    : slab?.quantity
                    ? `(x${slab?.quantity})`
                    : ''
                }
                `}</p>
            </div>
            <div>
              <p className="font-weight-bold pop_total">
                {!!fnb && fnb?.fnb_private_id === f.fnb_private_id ? (
                  <>
                    {curr_code}{' '}
                    {currencyFormatter(
                      fnb?.price *
                        (fnb?.quantity * seats > 0 ? seats : slab?.quantity) || 0,
                    )}
                  </>
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
        ))}

        {privateRateCardDetails.addOnsData.length > 0 && (
          <div className="add_private_pop">
            <p className="" style={{ color: 'white', fontWeight: 'bold' }}>
              {t('Add Ons')}
            </p>
            <p style={{ color: 'var(--yellow-shade)' }} className="ml-2">
              ({t('Select only one')})
            </p>
          </div>
        )}
        {privateRateCardDetails.addOnsData.map((a, i) => (
          <div className="all_btn_pop">
            <div className="checkbox-row" key={a.item_name}>
              <label className="custom-check">
                <input
                  type="checkbox"
                  name="addOn"
                  id={a.item_name}
                  checked={addon?.fnb_private_id === a.fnb_private_id}
                  onClick={(e) => updateAddon(e, a)}
                />
                <span />
              </label>
              <p className="mx-2 desktop_bottom">{a.item_name}</p>
              {/*<label className="ml-2 custom-check">
              <input
                type="checkbox"
                name="addOn"
                id={a.item_name}
                onClick={(e) => updateAddon(e, a)}
              />
              <span />
        </label>*/}
            </div>
            <div>
              <p className="font-weight-bold pop_total">
                {!!addon && addon?.fnb_private_id === a.fnb_private_id ? (
                  <>
                    {curr_code}{' '}
                    {currencyFormatter(addon?.price * addon?.quantity || 0)}
                  </>
                ) : (
                  ''
                )}
              </p>
            </div>
          </div>
        ))}

        {/*<div className="d-flex justify-content-between align-items-center pb-2">
          <p className="fs-16">
            {t("final_ticket_page.Tickets")} (
            {(booking_type === 3 || userSelection?.selectedScreeningType?.booking_type_id === 3)
              ? seats > 0
                ? seats
                : slab?.quantity || "All"
              : scheduleDetails?.ticket_count}
            ):{" "}
          </p>
          <p className="fs-22">
            {curr_code}{" "}
            {currencyFormatter(
              // slab.quantity * slab.price
              total
            )}
          </p>
            </div>*/}
        {discount && (
          <div className="d-flex justify-content-between align-items-center">
            <p className="total_para_pop">
              {t('Promocode Discount')}:
              <br />({appliedVoucher.voucher_title})
            </p>
            <p className="fs-22">
              - {curr_code} {currencyFormatter(discount)}
            </p>
          </div>
        )}
      </div>
      {/* End Middle */}

      <div className="d-flex justify-content-between footer">
        <div>
          <p className="total_pop">{t('Total Amount Payable')}: </p>
          <p className="total_para_pop text-grey font-italic">
            ({t('Amount inclusive of all taxes')})
          </p>
        </div>
        <p className="font-weight-bold pop_total">
          {curr_code}{' '}
          {currencyFormatter(
            // (slab.quantity * slab.price)
            total - (discount ? discount : 0),
          )}
        </p>
      </div>

      {/* End Footer */}
    </>
  );
};
