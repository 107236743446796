import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';
// import SpinnerCompo from '@components/partials/Spinner';
// Images
import Slider from 'react-slick';
import { ReactComponent as Facebook } from '@assets/pictures/svgs/facebook-active.svg';
import { ReactComponent as Instagram } from '@assets/pictures/svgs/instagram-active.svg';
import { ReactComponent as Twitter } from '@assets/pictures/svgs/twitter-active.svg';
import logoSample from '@assets/pictures/logo-colored.png';
import useIsTablet from '@src/customHooks/useIsTablet.js';
import { ReactComponent as Send } from '@assets/pictures/svgs/send.svg';
import { ReactComponent as HomeIcon } from '@assets/pictures/svgs/home_new.svg';
import { ReactComponent as TicketIcon } from '@assets/pictures/svgs/ticket_new.svg';
import { ReactComponent as ProfileIcon } from '@assets/pictures/svgs/profile__new.svg';
import { Container, Row, div, Dropdown, Modal } from 'react-bootstrap';
import MobileBottomMenu from '@components/partials/MobileBottomMenu.jsx';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { CityService, LanguageService } from '@apiService/tokenService';
import { globalConfigContext } from '@context/GlobalConfigContext';
import i18n from '@plugins/i18n';
import { motion } from 'framer-motion';
import GenericService from '@apiService/GenericService';
import cinepolLogo from '@assets/pictures/cinepol_logo.jpg';
import partnetLogo1 from '@assets/pictures/laserPt1.png';
import partnetLogo2 from '@assets/pictures/dolbyAtmosPt2.png';
import partnetLogo3 from '@assets/pictures/max4dpt3.png';
import partnetLogo4 from '@assets/pictures/DolbyCinemapt4.png';

import Logo1 from '@assets/pictures/fab.png';
import Logo2 from '@assets/pictures/omega.png';
import Logo3 from '@assets/pictures/PEPSI.png';

import appleLogo from '@assets/pictures/applestore.svg';
import googleLogo from '@assets/pictures/googlestore.svg';
import emaarLogo from '@assets/pictures/emaarlogo.png';

// import kinoLogo from '@assets/pictures/kino_stan.png';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'qs';
import { titleCase } from '@helper/formatting';
import { setShowLoginPopup } from '@store/auth/authActions';
// const LazyStickyQuickBook = React.lazy(() => import('@components/StickyQuickBook'));
function Footer({ t }) {
  const dispatch = useDispatch();

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser } = loginDetails;
  const isMobile = useIsMobile();
  const history = useHistory();
  const newsLetterEmail = useRef();
  const isTablet = useIsTablet();
  const subscribeMsg = useRef();
  const organization = useSelector((state) => state.movies.organizationDetails);

  const social_media = useSelector((state) => state.homepage.social_media_cms);
  const footer_cms = useSelector((state) => state.homepage.footer_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);
  const [showBottomMenu, setShowBottomMenu] = useState(true);

  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    org_address,
    org_emailid,
    org_contact_no,
    org_contact_no_code,
  } = organization;
  const { pathname } = useLocation();

  const handleLoginPopup = (toShow) => {
    dispatch(setShowLoginPopup(toShow));
  };

  useEffect(() => {
    // console.log("social_media", social_media);
  }, [social_media]);

  useEffect(() => {}, [pathname]);

  const currentTab = (path) => {
    let tab = '';
    if (path.startsWith('/home') || path.startsWith('/')) tab = 'homeTab';
    if (path.startsWith('/schedules')) tab = 'scheduleTab';
    if (path.startsWith('/guest-user-profile') || path.startsWith('/user-profile'))
      tab = 'userTab';

    return tab;
  };
  const [swiping, setSwiping] = useState(false);
  const footerSlider = {
    pauseOnFocus: false,
    onSwipe: () => setSwiping(true),
    arrows: false,
    dots: false,
    infinite: true,
    useTransform: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  async function subscribe() {
    const payload = {
      text: newsLetterEmail.current.value,
    };
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        payload.text,
      )
    ) {
      const response = await GenericService.GetNewsLetter(payload);
      if (response.status === 200 && response.data.status === true) {
        subscribeMsg.current.innerText = t('Thank you for subscribing');
        subscribeMsg.current.className = 'text-success';
      } else {
        subscribeMsg.current.innerText = t('You have already subscribed!');
        subscribeMsg.current.className = 'text-warning';
      }
      console.log(response);
    } else {
      subscribeMsg.current.innerText = t('Please enter a valid email');
      subscribeMsg.current.className = 'text-danger';
    }
  }

  const showItem = (item) => {
    if (!CMSLOADER) {
      if (footer_cms && footer_cms.length > 0) {
        if (footer_cms[0][item] == 'Y') {
          return true;
        } else if (footer_cms[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (social_media && social_media.length > 0) {
        if (social_media[0][item]) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const openUrl = (url) => {
    console.log('i am here', url);
    const newWindow = window.open(url, '_blank');
    if (newWindow) newWindow.opener = null;
  };

  if (
    pathname.startsWith('/schedules') ||
    pathname.startsWith('/fnb') ||
    pathname.startsWith('/create-show') ||
    pathname.startsWith('/screening-type') ||
    pathname.startsWith('/seat-layout') ||
    pathname.startsWith('/payment') ||
    pathname.includes('booking') ||
    pathname.startsWith('/final-ticket') ||
    pathname.startsWith('/unpaid-ticket') ||
    pathname.startsWith('/gift-card-details') ||
    pathname.startsWith('/gift-card-payment') ||
    pathname.startsWith('/gift-card-topup')

    // ||
    // (pathname.startsWith("/movie-details") &&
    //   pathname.split("/").length >= 5 &&
    //   pathname.split("/")[4] !== "2")
  ) {
    return null;
  }
  let current_pathname = window.location.href.split('/');

  let pathCheck = current_pathname[3];
  // console.log(current_pathname, 'current_pathname');
  return (
    <footer className="footer_new">
      {isMobile ? (
        // Mobile Design
        <>
          {showBottomMenu && (
            <div className="container-fluid">
              <section className="row">
                <article className="col-12">
                  <div className="footer_new_icon_links">
                    <HomeIcon
                      className={currentTab(pathname) === 'homeTab' ? 'active' : ''}
                      onClick={() => {
                        history.push('/');
                      }}
                    />
                    <TicketIcon
                      className={
                        currentTab(pathname) === 'scheduleTab' ? 'active' : ''
                      }
                      onClick={() => {
                        history.push('/schedules');
                      }}
                    />
                    {/*{!loggedInUser && guestUser && (
                      <p onClick={() => history.push("/guest-user-profile")}>
                        {t("My Profile")}
                      </p>
                    )}
                    {loggedInUser && (
                      <p onClick={() => history.push("/user-profile")}>
                        {t("My Profile")}
                      </p>
                    )}*/}
                    <ProfileIcon
                      className={currentTab(pathname) === 'userTab' ? 'active' : ''}
                      onClick={() => {
                        if (!loggedInUser && guestUser) {
                          history.push('/guest-user-profile');
                        } else if (loggedInUser) {
                          history.push('/user-profile');
                        } else {
                          handleLoginPopup(true);
                        }
                      }}
                    />
                  </div>
                </article>
              </section>
            </div>
          )}
        </>
      ) : (
        // Desktop/Tablet Design
        <>
          <div
            className="fixed_footer"
            style={{
              position: pathCheck != '' ? 'relative' : 'sticky',
              height: !isMobile && !isTablet && pathCheck == '' ? '547px' : '',
            }}
          >
            <section className="footer_main_wrapper">
              <article className="container-fluid">
                {/* <div className="footer_new_items"> */}
                {/* <figure className="footer_new_logo">
               {showItem("Logo") ? (
                      <Link className="mb-0" to="/">
                        <img
                          src={org_mycinema_logo || org_logo_url}
                          alt={org_name}
                          // width="115px"
                          width="200px"
                          className=""
                        />
                      </Link>
                    ) : (
                      <div
                        className="dummy-image"
                        style={{
                          width: "100px",
                        }}
                      ></div>
                    )}
        </figure> */}
                {/* {showItem('Logo') && (
                  <figure
                    className="footer_new_logo"
                    onClick={() => history.push('/')}
                  >
                    <img
                      src={
                        window.location.hostname.includes('cinepol'.toLowerCase())
                          ? cinepolLogo
                          : kinoLogo || kinoLogo
                      }
                      alt={org_name}
                      className=""
                    />
                  </figure>
                )}
                <figure className="sm_icons">
                  {showItem('Social Links') && (
                    <div className="footer-sm-links py-0">
                      {isItemActive('facebok_link') && (
                        <Facebook
                          onClick={() => openUrl(social_media[0]?.facebok_link)}
                        />
                      )}
                      {isItemActive('insta_link') && (
                        <Instagram
                          onClick={() => openUrl(social_media[0]?.insta_link)}
                        />
                      )}
                      {isItemActive('twitter_link') && (
                        <Twitter
                          onClick={() => openUrl(social_media[0]?.twitter_link)}
                        />
                      )}
                    </div>
                  )} */}
                {/* <Facebook />
          <Instagram/> */}
                {/* </figure>
                <h3>{t('Get Latest Movie Update')}</h3>
                <label className="footer_new_newsletter">
                  <input
                    type="email"
                    name=""
                    id=""
                    className=""
                    placeholder={t('Enter your Email ID')}
                    ref={newsLetterEmail}
                  />
                  <Send
                    onClick={(e) => {
                      e.preventDefault();
                      subscribe();
                      newsLetterEmail.current.value = '';
                    }}
                  />
                </label>
                <p ref={subscribeMsg} />

                <ul className="list-inline">
                  <li className="list-inline-item">
                    {showItem('About US') && (
                      <Link to="/about-us" className="footer_new_link">
                        {t('common.About Us')}
                      </Link>
                    )}
                  </li>
                  <li className="list-inline-item">
                    {showItem('Contact US') && (
                      <Link to="/contact-us" className="footer_new_link">
                        {t('Contact Us')}
                      </Link>
                    )}
                  </li>
                  <li className="list-inline-item">
                    {showItem('FAQs') && (
                      <Link to="/faqs" className="footer_new_link">
                        {t('common.FAQs')}
                      </Link>
                    )}
                  </li>
                  <li className="list-inline-item">
                    {showItem('Terms n Conditions') && (
                      <Link to="/tnc" className="footer_new_link">
                        {t('common.Terms and Conditions')}
                      </Link>
                    )}
                  </li>
                  <li className="list-inline-item">
                    {showItem('Privacy Policy') && (
                      <Link to="/privacy-policy" className="footer_new_link">
                        {t('common.Privacy Policy')}
                      </Link>
                    )}
                  </li>
                </ul>
                <h6>&copy; {t('All Rights Reserved')}</h6>
              </div> */}
                {/* new footer design start */}
                {/* <hr style={{ color: 'var(--light-1)' }} /> */}
                {/* <div style={{marginTop:'1px solid red'}}> */}
                <div className="row">
                  <div className="col-3 py-4">
                    {/* {showItem('Logo') && (
                  <figure
                    className="footer_new_logo"
                    onClick={() => history.push('/')}
                  >
                    <img
                      src={
                        window.location.hostname.includes('cinepol'.toLowerCase())
                          ? cinepolLogo
                          : kinoLogo || kinoLogo
                      }
                      alt={org_name}
                      className=""
                    />
                  </figure>
                )} */}
                    <p className="primary mb-2">{org_name}</p>
                    <p className="font_grey">{org_address}</p>
                    {/* <p className="font_grey">6370 Stans</p> */}
                    <p className="font_grey pt-3">
                      Telefon: {org_contact_no_code}&nbsp;{org_contact_no}
                    </p>
                    {/* <figure
                    className="sm_icons"
                    // style={{ marginLeft: '16px' }}
                  >
                    {showItem('Social Links') && (
                      <div className="footer-sm-links py-0">
                        {isItemActive('facebok_link') && (
                          <Facebook
                            onClick={() => openUrl(social_media[0]?.facebok_link)}
                          />
                        )}
                        {isItemActive('insta_link') && (
                          <Instagram
                            onClick={() => openUrl(social_media[0]?.insta_link)}
                          />
                        )}
                        {isItemActive('twitter_link') && (
                          <Twitter
                            onClick={() => openUrl(social_media[0]?.twitter_link)}
                          />
                        )}
                      </div>
                    )}
              <Facebook />
          <Instagram/>
                  </figure> */}
                    {/* <h3 className="text-white">{t('Get Latest Movie Update')}</h3> */}
                  </div>

                  <div className="col-3 py-4">
                    <h3
                      className="primary"
                      // style={{ paddingTop: '20px' }}
                    >
                      {t('Opening Hours')}
                    </h3>
                    <p className="font_grey footer_font-size">
                      {t('kino_footer_para')}
                    </p>
                  </div>

                  <div className="col-3 py-4">
                    <ul className="" style={{ listStyleType: 'none' }}>
                      <h3
                        className="primary"
                        // style={{ paddingTop: '20px' }}
                      >
                        Sitemap
                      </h3>
                      {/* <li className="footer-list-style-type mb-2" >
                    {showItem('About US') && (
                      <Link to="/about-us" className="footer_new_link text-white">
                        {t('common.About Us')}
                      </Link>
                    )}
                      </li> */}

                      {/* <li className="footer-list-style-type mb-2 text-white">
                    {showItem('Contact US') && (
                      <Link to="/contact-us" className="footer_new_link text-white">
                        {t('Contact Us')}
                      </Link>
                    )}
                  </li> */}
                      <li className="footer-list-style-type mb-2">
                        {showItem('opening-hours') && (
                          <Link
                            to="/opening-hours"
                            className="footer_new_link font_grey"
                          >
                            {t('Opening Hours')}
                          </Link>
                        )}
                      </li>
                      <li className="footer-list-style-type mb-2">
                        {showItem('FAQs') && (
                          <Link to="/faqs" className="footer_new_link font_grey">
                            {t('common.FAQs')}
                          </Link>
                        )}
                      </li>
                      <li className="footer-list-style-type mb-2">
                        {showItem('Contact') && (
                          <Link
                            to="/contact"
                            style={{ marginRight: '12px' }}
                            className="footer_new_link font_grey"
                          >
                            {t('contact_title_new')}
                          </Link>
                        )}
                      </li>
                      <li className="footer-list-style-type mb-2">
                        {showItem('tnc') && (
                          <Link
                            to="/tnc"
                            style={{ marginRight: '12px' }}
                            className="footer_new_link font_grey"
                          >
                            {t('Terms & Conditions')}
                          </Link>
                        )}
                      </li>
                      <li className="footer-list-style-type mb-2">
                        {showItem('Privacy Policy') && (
                          <Link
                            to="/privacy-policy"
                            className="footer_new_link font_grey"
                          >
                            {t('common.Privacy Policy')}
                          </Link>
                        )}
                      </li>
                      {/* <li className="footer-list-style-type mb-2">
                      {showItem('Terms n Conditions') && (
                        <Link to="/tnc" className="footer_new_link font_grey">
                          {t('common.Terms and Conditions')}
                        </Link>
                      )}
                    </li> */}
                      {/* <li className="footer-list-style-type mb-2">
                      {showItem('Privacy Policy') && (
                        <Link
                          to="/privacy-policy"
                          className="footer_new_link font_grey"
                        >
                          {t('common.Privacy Policy')}
                        </Link>
                      )}
                    </li> */}
                      <li className="footer-list-style-type mb-2">
                        {showItem('Imprint') && (
                          <Link
                            to="/imprint"
                            className="footer_new_link font_grey "
                          >
                            {t('imprint_title')}
                          </Link>
                        )}
                      </li>
                      {/* <li className="footer-list-style-type mb-2">
                    {showItem('Contact') && (
                      <Link to="/contact" className="footer_new_link font_grey">
                        {t('contact_title')}
                      </Link>
                    )}
                  </li> */}
                    </ul>
                  </div>

                  <div className="col-3 py-4 text-right">
                    <p
                      className="font_grey mb-2 footer_font-size "
                      // style={{ paddingTop: '20px' }}
                    >
                      {t('newslettre_para')}
                    </p>
                    <label className="footer_new_newsletter">
                      <input
                        // style={{marginLeft:'16px'}}
                        type="email"
                        name=""
                        id=""
                        className=""
                        placeholder={t('Enter your Email ID')}
                        ref={newsLetterEmail}
                      />
                      {/* <Send
                      onClick={(e) => {
                        e.preventDefault();
                        subscribe();
                        newsLetterEmail.current.value = '';
                      }}
                    /> */}
                    </label>
                    <button
                      className="btn-main ml-auto mt-2"
                      onClick={(e) => {
                        e.preventDefault();
                        subscribe();
                        newsLetterEmail.current.value = '';
                      }}
                    >
                      {t('Send_text')}
                    </button>
                    <p ref={subscribeMsg} />
                  </div>

                  <div className="col-xl-4 col-md-12 py-4 before_hr_class">
                    <h3
                      className="primary mb-3"
                      // style={{ paddingTop: '20px' }}
                    >
                      Technology Partners
                    </h3>
                    <Slider
                      {...footerSlider}
                      className="home_banner"
                      style={{ marginBottom: '0px', marginLeft: '0px !important' }}
                    >
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={partnetLogo1}
                          alt="pt1"
                          className="img-fluid "
                          // href={x.pp_image_url}
                        />
                      </div>
                      {/* <span>{x.pp_title}</span> */}

                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={partnetLogo2}
                          alt="pt1"
                          className="img-fluid "
                          // href={x.pp_image_url}
                        />
                      </div>
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={partnetLogo3}
                          alt="pt1"
                          className="img-fluid "
                          // href={x.pp_image_url}
                        />
                      </div>
                      <div>
                        <img
                          style={{ cursor: 'pointer' }}
                          src={partnetLogo4}
                          alt="pt1"
                          className="img-fluid "
                          // href={x.pp_image_url}
                        />
                      </div>
                    </Slider>
                  </div>

                  <div className="col-xl-4 col-md-12 py-4 before_hr_class">
                    <h3 className="primary" style={{ paddingLeft: '24px' }}>
                      Partners
                    </h3>

                    <div className="partners_logo_wrapper">
                      <a
                        href="https://www.bankfab.com/en-ae/personal"
                        target="_blank"
                      >
                        <img
                          style={{ cursor: 'pointer' }}
                          src={Logo1}
                          alt="pt1"
                          className=""
                        />
                      </a>

                      <a href="https://www.omegawatches.com/" target="_blank">
                        <img
                          style={{ cursor: 'pointer' }}
                          src={Logo2}
                          alt="pt1"
                          className=""
                        />
                      </a>

                      <a href="https://www.pepsico.com/" target="_blank">
                        <img
                          style={{ cursor: 'pointer' }}
                          src={Logo3}
                          alt="pt1"
                          className="custom_partners_logo"
                        />
                      </a>
                    </div>
                  </div>

                  <div className="col-xl-4 col-md-12 py-4 text-right before_hr_class">
                    {/* <h3 className="primary" style={{ paddingLeft: '24px' }}>
                      Partners
                    </h3> */}

                    <div className="app_logo_wrapper" style={{ gap: '15px' }}>
                      <a
                        href="https://www.bankfab.com/en-ae/personal"
                        target="_blank"
                      >
                        <img
                          style={{ cursor: 'pointer' }}
                          src={appleLogo}
                          alt="pt1"
                          className="app_down_logo"
                        />
                      </a>

                      <a href="https://www.omegawatches.com/" target="_blank">
                        <img
                          style={{ cursor: 'pointer' }}
                          src={googleLogo}
                          alt="pt1"
                          className="app_down_logo"
                        />
                      </a>
                    </div>
                    <div
                      className="partners_logo_wrapper mt-3"
                      style={{ gap: '15px' }}
                    >
                      <img
                        style={{ cursor: 'pointer' }}
                        src={emaarLogo}
                        alt="pt1"
                        className=""
                      />

                      <p
                        className="mb-0 text-left font_grey footer_font-size"
                        style={{ fontWeight: '200' }}
                      >
                        Copyright © Reel Entertainment LLC. All rights reserved.
                      </p>
                    </div>
                  </div>
                </div>

                {/* new footer design end */}
              </article>

              <div className="mt-2  row bg_dark_grey  container-fluid ">
                <div className="col-6 pt-3  before_hr_class">
                  <img
                    style={{ cursor: 'pointer' }}
                    src={emaarLogo}
                    alt="pt1"
                    className=""
                  />
                </div>

                <div className="col-6 pt-3  before_hr_class d-flex justify-content-end align-items-center">
                  <p
                    className="mb-0 text-left font_grey footer_font-size"
                    style={{ fontWeight: '200' }}
                  >
                    Emaar Properties PJSC is the Master Developer of Reel Cinemas
                  </p>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </footer>
  );
}
export default withNamespaces()(Footer);
