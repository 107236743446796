import React from 'react';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const AgeRating = ({ t }) => {
  const organization = useSelector((state) => state.movies.organizationDetails);
  const { org_logo_url, org_mycinema_logo, org_name } = organization;
  // let cinema_name = window.location.hostname.includes('cinepol'.toLowerCase());
  let categories = [
    {
      age: '3',
      header: 'age_rating_caterory3_title',
      description: 'age_rating_caterory3_data',
    },
    {
      age: '6',
      header: 'age_rating_caterory6_title',
      description: 'age_rating_caterory6_data',
    },
    {
      age: '8',
      header: 'age_rating_caterory8_title',
      description: 'age_rating_caterory8_data',
    },
    {
      age: '10',
      header: 'age_rating_caterory10_title',
      description: 'age_rating_caterory10_data',
    },
    {
      age: '12',
      header: 'age_rating_caterory12_title',
      description: 'age_rating_caterory12_data',
    },
    {
      age: '14',
      header: 'age_rating_caterory14_title',
      description: 'age_rating_caterory14_data',
    },
    {
      age: '16',
      header: 'age_rating_caterory16_title',
      description: 'age_rating_caterory16_data',
    },
    {
      age: 'TBA',
      header: 'age_rating_tobe_announced_title',
      description: 'age_rating_tobe_announced_data',
    },
  ];
  return (
    <>
      <section className="about_us_age_rating_wrapper align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={t('Rating.AgeRatingAt') + ' ' + org_name}
          />
          <CenteredTitle
            firstHalf={t('Rating.AgeRatingAt') + ' ' + org_name}
            secondHalf=""
          />
        </div>
        <div className="row age_back">
          <div className="col-xl-10 mx-auto">
            <div className="mb-4 mb-md-5">
              <p>{t('age_rating_data_para1')}</p>
              <p>{t('age_rating_data_para2')}</p>
              <p>{t('age_rating_data_para3')}</p>
              <p>{t('age_rating_data_para4')}</p>
            </div>
          </div>
          {categories.map((item, index) => (
            <article className="col-md-6 col-xl-5 ml-xl-auto" key={index}>
              <div className="about_us_age_rating">
                <article className="about_us_spacing">
                  <span style={{ marginRight: '10px' }}>{item.age}</span>
                  <p>{t(item.header)}</p>
                </article>
                <p>{t(item.description)}</p>
              </div>
            </article>
          ))}
          <div className="col-xl-10 mx-auto">
            <p className="mt-4 mt-md-5">
              <span className="age_rating_highlight">{t('Rating.Note')}</span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AgeRating);
