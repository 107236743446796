const PRIMARY = { key: '--primary', value: '#6e3996' };
const DISABLED = { key: '--disabled', value: '#D3D3D3' };
const TEXTLIGHT = { key: 'text-light', value: '#f5f5f5' };
const DARK_1 = { key: '--dark-1', value: '#f4f2ed' };
const DARK_2 = { key: '--dark-2', value: '#fff' };
const DARK_3 = { key: '--dark-3', value: '#ffffff' };
const DARK_4 = { key: '--dark-4', value: '#ffffff' };
const DARK_5 = { key: '--dark-5', value: '#f6f6f6' };
const DARK_6 = { key: '--dark-6', value: '#ffffff' };
const DARK_7 = { key: '--dark-7', value: '#151515' };
const DARK_8 = { key: '--dark-8', value: '#4b4a52' };
const DARK_9 = { key: '--dark-9', value: '#f6f6f6' };
const DARK_10 = { key: '--dark-10', value: '#6e3996' };
const DARK_11 = { key: '--dark-11', value: '#343434' };
const DARK_12 = { key: '--dark-12', value: '#262626' };
const LIGHT_1 = { key: '--light-1', value: '#000' };
const LIGHT_2 = { key: '--light-2', value: '#f4f2ed' };
const LIGHT_3 = { key: '--light-3', value: '#f3f3f3' };
const LIGHT_4 = { key: '--light-4', value: '#979797' };
const LIGHT_5 = { key: '--light-5', value: '#343438' };
const LIGHT_6 = { key: '--light-6', value: '#6e3996' };
const LIGHT_7 = { key: '--light-7', value: '#acacac' };
const LIGHT_8 = { key: '--light-8', value: '#dfdfde' };
const YELLOW_SHADE = { key: '--yellow-shade', value: '#c09e3f' };
const GREEN_SHADE = { key: '--green-shade', value: '#45bb4d' };
const BLUE_SHADE = { key: '--blue-shade', value: '#25aad3' };
const PURPLE_SHADE = { key: '--purple-shade', value: '#564aac' };
const EXCLUSIVE = { key: '--exclusive', value: '#d4af37' };

const SCHEDULE_DATE_BG = { key: '--schedule-date-bg', value: '#ffffff' };
const SCHEDULE_TIME = { key: '--schedule-time', value: '#555558' };
const NAV_TEXT = { key: '--nav-text', value: '#555558' };
const SELECTED_TAB = { key: '--selected-tab', value: '#241d1a' };
const UNSELECTED_TAB = { key: '--unselected-tab', value: '#555558' };
const NAVBAR_BG = { key: '--navbar-bg', value: '#ffffff' };
const SELECT_LANGUAGE = { key: '--select-language', value: '#ffffff' };
const SELECT_DATE = { key: '--select-date', value: '#ffffff' };
const BACK_ICON = { key: '--back-icon', value: '#fff' };
const SELECTED_SESSION = { key: '--selected-session', value: '#fff' };
const MOVIE_MIN = { key: '--movie-min', value: '#fff' };
const SCHEDULE_INFO = { key: '--schedule-info', value: '#cd1719' };

const SEAT_CELL_ENABLED = { key: '--seat-cell-enabled', value: '#cd1719' };
const SEAT_CELL_DISABLED = { key: '--seat-cell-disabled', value: '#dfdfde' };
const SEAT_CELL_AVAILABLE = { key: '--seat-cell-available', value: '#555558' };
const SEAT_PLAN_BTN = { key: '--seat-plan-btn', value: PRIMARY.value };
const SEAT_PLAN_BTN_BG = { key: '--seat-plan-btn-bg', value: '#fff' };
const BOOK_CNF_HEADER = { key: '--book-cnf-header', value: '#fff' };
const BOOKING_SUMMARY_BG = { key: '--booking-summary-bg', value: '#fff' };
const CHECKOUT_SIGNIN_BTN = { key: '--checkout-signin-btn', value: '#ffffff' };
const LOGIN_UTIL_BG = {
  key: '--login-util-bg',
  value: PRIMARY.value,
};
const TEXT_COLOR_SECONDARY = {
  key: '--text-color-secondary',
  value: '#ffffff',
};
const GOLD_CARD_HOVER = {
  key: '--gold-card-hover',
  value: '#fff',
};
const PROMO_INPUT = {
  key: '--promo-input',
  value: '#ede2f',
};
const LOGIN_SVG = {
  key: '--login-svg',
  value: '#000',
};
const PROFILE_ICON = {
  key: '--profile-icon',
  value: '#555558',
};
const COUNTRY_TEXT = {
  key: '--country-text',
  value: '#000',
};
export default [
  DARK_1,
  PRIMARY,
  DISABLED,
  TEXTLIGHT,
  DARK_2,
  DARK_3,
  DARK_4,
  DARK_5,
  DARK_6,
  DARK_7,
  DARK_8,
  DARK_9,
  DARK_10,
  DARK_11,
  DARK_12,
  LIGHT_1,
  LIGHT_2,
  LIGHT_3,
  LIGHT_4,
  LIGHT_5,
  LIGHT_6,
  LIGHT_7,
  LIGHT_8,
  YELLOW_SHADE,
  GREEN_SHADE,
  BLUE_SHADE,
  PURPLE_SHADE,
  EXCLUSIVE,
  SCHEDULE_DATE_BG,
  NAV_TEXT,
  SELECTED_TAB,
  UNSELECTED_TAB,
  NAVBAR_BG,
  SELECT_LANGUAGE,
  SELECT_DATE,
  BACK_ICON,
  SELECTED_SESSION,
  MOVIE_MIN,
  SCHEDULE_INFO,
  SCHEDULE_TIME,
  SEAT_CELL_ENABLED,
  SEAT_CELL_DISABLED,
  SEAT_CELL_AVAILABLE,
  SEAT_PLAN_BTN,
  SEAT_PLAN_BTN_BG,
  BOOK_CNF_HEADER,
  BOOKING_SUMMARY_BG,
  CHECKOUT_SIGNIN_BTN,
  LOGIN_UTIL_BG,
  TEXT_COLOR_SECONDARY,
  GOLD_CARD_HOVER,
  PROMO_INPUT,
  LOGIN_SVG,
  PROFILE_ICON,
  COUNTRY_TEXT,
];
