import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

// Imagesrou
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import classImg from '@assets/pictures/classs_img.png';
const Imprint = ({ t }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('imprint_title')} />
        <CenteredTitle firstHalf={t('imprint_title')} secondHalf={''} />
      </div>
      <div className="container-fluid row">
        <div className=" col-12 mx-auto d-flex">
          <div className="col-md-4">
            {/* <p>{t('imprint_cinepol-subtitle')}</p> */}
            <p className="text-primary mb-2">{t('imprint_address_cinepol')}</p>
            <p className="static_page">{t('cinema_cinepol_heading')}</p>
            <p className="static_page">
              <span>{t('cinema_cinepol_28')}</span>&nbsp;&nbsp;
              <span> {t('cinema_cinepol_5643')}</span>
            </p>
            {/* <p>{t('cinema_cinepol_5643')}</p> */}
            <p className="static_page">{t('cinema_cinepol_5643_mail')}</p>
          </div>
          <div className="col-md-4">
            <p className="text-primary mb-2">{t('cinema_cinepol_operator')}</p>
            <p className="static_page">{t('cinema_cinepol_association_culture')}</p>
            <p className="static_page">{t('cinema_cinepol_Bahnhofstrasse')}</p>
            <p className="static_page">{t('cinema_cinepol_5643_sins')}</p>
          </div>
          <div className="col-md-4">
            <p className="text-primary mb-2">{t('cinema_cinepol_contact_title')}</p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_telephone')}</span>
              <span>{t('cinema_cinepol_contact_telephone_number')}</span>
            </p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_email')}</span>
              <span>{t('cinema_cinepol_contact_email_id')}</span>
            </p>
            <p className="static_page">{t('cinema_cinepol_5643_sins')}</p>
          </div>
        </div>
        <div className="d-flex col-12 ">
          <div className="col-md-4 mt-3">
            <p className="text-primary mb-2">
              {t('cinema_cinepol_contact_register_entry')}
            </p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_register_company')}</span>
              <span>{t('cinema_cinepol_contact_register_company_data')}</span>
            </p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_register_company_number')}</span>:
              <span>{t('cinema_cinepol_contact_register_number_data')}</span>
            </p>
          </div>
          <div className="col-md-4 mt-3">
            <p className="text-primary mb-2">
              {t('cinema_cinepol_contact_bank_details')}
            </p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_bank_details_name')}</span>
              <span>{t('cinema_cinepol_contact_bank_details_data')}</span>
            </p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_bank_account_holder')}</span>:&nbsp;
              <span>{t('cinema_cinepol_contact_bank_account_holder_name')}</span>
            </p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_clearing_name')}</span>
              <span>{t('cinema_cinepol_contact_clearing_data')}</span>
            </p>
            <p className="static_page">
              <span>{t('cinema_cinepol_contact_IBAN')}</span>
              <span>{t('cinema_cinepol_contact_IBAN_number')}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(Imprint);
