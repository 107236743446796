import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import classImg from '@assets/pictures/classs_img.png';
const Contact = ({ t }) => {
  const isMobile = useIsMobile();
  const organization = useSelector((state) => state.movies.organizationDetails);
  // console.log(organization, 'organization');
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    org_address,
    org_emailid,
    org_contact_no,
    org_contact_no_code,
  } = organization;
  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('contact_title')} />
        <CenteredTitle firstHalf={t('contact_title')} secondHalf={''} />
      </div>

      <div className="container-fluid opening_hours">
        <section className="row">
          <article className="col-12 mx-auto">
            <p className="mb-2 text-primary data-title-size">
              {/* {t('contact_cinepol_heading1')} */}
              {org_name}
            </p>
            {/* <p>{t('contact_cinepol_heading1')}</p> */}
            {/* <p>{t('contact_cinepol_heading2')}</p>
            <p>{t('contact_cinepol_heading3')}</p> */}
            <p>{org_address}</p>
            <p className="mb-2">
              <span className="text-primary">
                {t('contact_cinepol_telephone')}{' '}
              </span>
              {/* <span>{t('contact_cinepol_telephone_number')}</span> */}
              <span>
                {org_contact_no_code}&nbsp;{org_contact_no}
              </span>
            </p>
            <p className="mb-2">
              <span className="text-primary">{t('contact_cinepol_email')} </span>
              {/* <span>{t('contact_cinepol_email_address')}</span> */}
              <span>{org_emailid}</span>
            </p>
            {/* <p className="mb-2">{t('contact_cinepol_note1')}</p> */}
            <p className="mb-2">{t('contact_cinepol_note2')}</p>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Contact);
