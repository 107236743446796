import React, { useEffect, useState, useRef, useContext } from 'react';
import { withNamespaces } from 'react-i18next';
import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
import cinemaBg from '@assets/pictures/cinema-banner.png';
import cinema from '@assets/pictures/cinema.png';
import whiteArrowDown from '@assets/pictures/svgs/white-arrow-down.svg';
import map from '@assets/pictures/svgs/map.svg';
import Slider from 'react-slick';
import AuditoriumCard from './partials/AuditoriumCard.jsx';
import AncilliaryHeroSection from './partials/AncilliaryHeroSection.jsx';
import { fetchCinemas } from '../store/movies/movieActions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';
import Spinner from './partials/Spinner';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import Nav from 'react-bootstrap/Nav';
import EventImg1 from '@assets/pictures/RIZ05053.JPG';
// import EventImg2 from '@assets/pictures/RIZ04736.JPG';
import EventImg2 from '@assets/pictures/sample-bg.png';
import EventImg3 from '@assets/pictures/RIZ04064.JPG';
// import EventVideo1 from '@assets/pictures/video_cinema.MP4';
import EventVideo1 from '@assets/pictures/star_video.mp4';
import EventVideo2 from '@assets/pictures/iratta.mp4';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import AdvertiseForm from './AdvertiseForm.jsx';
const Events = ({ t }) => {
  const dispatch = useDispatch();
  //   const cinemas = useSelector((state) => state.cinemas);
  //   const { cinemasLoading, allCinemas } = cinemas;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const history = useHistory();
  // const [cinemaDetails, setCinemaDetails] = useState(false);
  // if (cinemaDetails === true) {
  //   history.push("/cinema-details");
  // }
  // useEffect(() => {
  //   if (!cinemas?.allCinemas) {
  //     dispatch(fetchAllCinemas());
  //   }
  //   window.scrollTo(0, 0);
  // }, []);
  //   useEffect(() => {
  //     dispatch(fetchAllCinemas());
  //   }, [dispatch]);
  useEffect(() => window.scrollTo(0, 0), []);
  const heroSectionObj = {
    arr: [1],
    backgroundImg: EventImg2,
    // title: t('cinema_listing.maintitle'),
    // msg: t('cinema_listing.maindesc'),
  };
  const [key, setKey] = useState('home');

  return (
    <>
      <style>
        {`    .banner-image
            {
            width: 100%;
            background-image: url('@/assets/pictures/untitled-27.jpg');
            height: 100%;
            // background-color: purple;
            background-position: center;
            }`}
      </style>
      <div>
        <div className="container-fluid align_new">
          {/* <BreadCrumb firstHalf={`${t('Home')} >`} secondHalf={t('Location')} /> */}
          <CenteredTitle firstHalf={'Updates'} secondHalf={''} />
        </div>
        <div className="px-0">
          <section>
            <div className="banner-image">
              <img src={EventImg2} className="banner-image"></img>
            </div>
          </section>
          <section className="container-fluid container-xl mt-3 location_wrapper">
            <div className="">
              <p className="awu_sub_text">
                Brij Cinemas since its inception have launched an array of events.
                The events range from film screenings to premieres, themed party and
                interactive game nights. Here’s a glimpse of all the stunning events
                that took place at Brij Cinemas.
              </p>
              <Row>
                <div
                  className={`${
                    isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                  }`}
                >
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`d-flex justify-content-center align-items-center ${
                      isMobile ? 'mb-5' : 'mb-5'
                    }`}
                  >
                    {/* <img src={AdvertiseImg1} className="img-fluid awu_img" /> */}
                    {/* <video src={EventVideo1} className="img-fluid awu_img"></video> */}
                    <video
                      // width="320" height="240"
                      className="img-fluid awu_img"
                      controls
                    >
                      <source src={EventVideo1} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Col>

                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                  >
                    <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                      <h4 className="font-weight-bold">1. Brij Cinemas Opening </h4>
                      <p className="awu_sub_text">
                        A momentous occasion! Brij Cinemas, Al Ghurair was
                        inaugurated on Sept 28th, 2022. Promising a state-of-art
                        experience, the launch had celebrities across industries
                        showering their presence. Amazing performances and
                        never-before-seen festivities were on display on the opening
                        day! The launch was unlike anything UAE has ever seen.
                      </p>
                    </div>
                  </Col>
                </div>

                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                >
                  <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                    <h4 className="font-weight-bold">
                      2. Glitz, Glam and An Absolute Blast!
                    </h4>
                    <p className="awu_sub_text">
                      Nivin Pauly, Saiju Kurup, Aju Varghese, Saniya Iyappan, Grace
                      Antony, and other talents graced the Saturday Night Premiere.
                      The occasion was a noteworthy celebration, and at Brij
                      Cinemas, it had a festive atmosphere.
                    </p>
                  </div>
                </Col>

                <Col
                  sm={6}
                  lg={6}
                  xl={6}
                  className={`d-flex justify-content-center align-items-center ${
                    isMobile ? 'mb-5' : 'mb-5'
                  }`}
                >
                  <img src={EventImg1} className="img-fluid awu_img" />
                </Col>

                <div
                  className={`${
                    isMobile ? 'd-flex flex-wrap flex-column-reverse' : 'd-flex'
                  }`}
                >
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`d-flex justify-content-center align-items-center ${
                      isMobile ? 'mb-5' : 'mb-5'
                    }`}
                  >
                    {/* <img src={EventImg3} className="img-fluid awu_img" /> */}
                    <video
                      // width="320" height="240"
                      className="img-fluid awu_img"
                      controls
                    >
                      <source src={EventVideo2} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Col>
                  <Col
                    sm={6}
                    lg={6}
                    xl={6}
                    className={`${isMobile ? 'mb-2' : 'mb-5'}`}
                  >
                    <div className={` ${isMobile ? ' ' : 'mt-5'}`}>
                      <h4 className="font-weight-bold">3. Iratta Premier </h4>
                      <p className="awu_sub_text">
                        Joju George attended the Iratta movie premiere alongside his
                        co-stars Anjali, Srinda, Srikant Murali, and Sabumon. The
                        public was looking forward to seeing their favorite artists,
                        and they were thrilled to see them up close. Photo sessions
                        and interaction with the talents brought cheers alongside
                        the presence of a special screening for the Media
                        fraternity. This was indeed a memorable promotion for Brij
                        Cinemas.
                      </p>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(Events);
