import React from 'react';
import { withNamespaces } from 'react-i18next';

import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import RentOurSpaceImg from '@assets/pictures/rent-our-space.png';
import RentOurSpaceImg1 from '@assets/pictures/rent-out-space1.png';
import RentOurSpaceImg2 from '@assets/pictures/rent-out-space2.png';
import RentOurSpaceImg3 from '@assets/pictures/rent-out-space3.png';
import RentOurSpaceImg4 from '@assets/pictures/Rent-1.jpg';
import RentOurSpaceImg5 from '@assets/pictures/Rent-2.jpg';
import RentOurSpaceImg6 from '@assets/pictures/Rent-3.jpg';

const RentOurSpace = ({ t }) => {
  let imgs = [RentOurSpaceImg4, RentOurSpaceImg5, RentOurSpaceImg6];

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Rent our Space')} />
        <CenteredTitle firstHalf={t('Rent our Space')} />
      </div>
      <div className="container-fluid rent_our_space">
        <section className="row">
          {imgs.map((img, i) => (
            <article className="col-md-6 col-xl-4 mb-4 mb-xl-0" key={i}>
              <img src={img} alt="Rent our space" className="" />
            </article>
          ))}
        </section>
      </div>

      <div className="container-fluid static_page">
        <section className="row">
          <article className="col-xl-10 mx-auto">
            <p className="my-4 my-xl-5"> {t('rent_our_space_data_para1')}</p>
            <p className="mb-2 text-primary data-title-size">
              {t('rent_our_space_subtitle_program')}
            </p>
            <p className="mb-4 mb-xl-5">{t('rent_our_space_data_para2')}</p>
            <p className="mb-2 text-primary data-title-size">
              {t('rent_our_space_subtitle_booking')}
            </p>
            <p className="mb-4 mb-xl-5">{t('rent_our_space_data_para3')}</p>
            {/* <p className="mt-4 mt-xl-5">You Cinema Address</p> */}
            {/* <p className="mt-4 mt-xl-5">
              Kino Stüssihof
            </p>
            <p>
              Stüssihofstatt 13
            </p>
            <p>
              CH-8001 Zürich
            </p> */}
            {/* <p className="text-primary">Yourcinema@yourcinema.com</p> */}
            {/* <p className="text-primary">
              stüssihof.ch
            </p> */}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(RentOurSpace);
