import React from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import { array } from 'yup';
import BreadCrumb from '@components/partials/BreadCrumbs';
import useIsTablet from '@src/customHooks/useIsTablet';
import useIsMobile from '@src/customHooks/useIsMobile';

const TariffsAndVouchers = (props) => {
  const { t, classes = '' } = props;
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  let tariffs1 = [
    {
      header: [
        { title: `${t('Prices_text')}`, subTitle: '' },
        { title: `${t('Regular_Show')}`, subTitle: '' },
        { title: `${t('Kids_program')}`, subTitle: '' },
      ],
      body: [
        {
          title: `${t('Adult')}`,
          subTitle: ``,
          tariff: [{ value: '17.00' }, { value: '15.50' }],
        },
        {
          title: `${t('Teenagers_new')}`,
          subTitle: `${t('Up_to_16_years')}`,
          tariff: [{ value: '15.50' }, { value: '15.50' }],
        },
        {
          title: `${t('Children_ up_to_12')}`,
          subTitle: `${t('')}`,
          tariff: [{ value: '15.00' }, { value: '14.00' }],
        },
        // {
        //   title: `${t('On-demand')}`,
        //   subTitle: ``,
        //   tariff: [
        //     { value: `${t('From')} 299.00` },
        //     { value: `${t('From')} 299.00` },
        //   ],
        // },
        // {
        //   title: `${t('Confirmed Screening')}`,
        //   subTitle: ``,
        //   tariff: [
        //     { value: `${t('From')} 180.00 (ab 10 Tickets)` },
        //     { value: `${t('From')} 80.00 (ab 5 Tickets)` },
        //   ],
        // },
        // {
        //   title: `${t('Rent your Stussihof')} 2h`,
        //   subTitle: `${t('(w/o movie)')}`,
        //   tariff: [{ value: `350.00` }, { value: `250.00` }],
        // },
        // {
        //   title: `${t('Rent your Stussihof')} 4h`,
        //   subTitle: `${t('(w/o movie)')}`,
        //   tariff: [{ value: `550.00` }, { value: `450.00` }],
        // },
        // {
        //   title: `${t('Rent your Stussihof')} 8h`,
        //   subTitle: `${t('(w/o movie)')}`,
        //   tariff: [{ value: `850.00` }, { value: `650.00` }],
        // },
        // {
        //   title: `${t('Saale Pigale')} 1h`,
        //   subTitle: ``,
        //   tariff: [{ value: `250.00` }, { value: `` }],
        // },
        // {
        //   title: `${t('Per Hour')}`,
        //   subTitle: ``,
        //   tariff: [{ value: `+100.00` }],
        // },
        // {
        //   title: `${t('Whole Day')}`,
        //   subTitle: ``,
        //   tariff: [{ value: `650.00` }],
        // },
      ],
    },
  ];

  let tariffs2 = [
    {
      header: [
        { title: `${t('Cinema_tickets')}`, subTitle: '' },
        { title: `${t('Each_show')}`, subTitle: '' },
        // { title: `${t('')}  `, subTitle: '' },
      ],
      body: [
        {
          title: `${t('Card_holders')}`,
          subTitle: ``,
          tariff: [{ value: '14.50' }, { value: '' }],
        },
        {
          title: `${t('First_accompaniment')}`,
          subTitle: `${t('')}`,
          tariff: [{ value: '14.50' }, { value: '' }],
        },
        {
          title: `${t('Any_other_accompaniment')}`,
          subTitle: `${t('up_to_a_group')}`,
          tariff: [{ value: '15.50' }, { value: '' }],
        },
        // {
        //   title: `${t('On-demand')}`,
        //   subTitle: ``,
        //   tariff: [
        //     { value: `${t('From')} 299.00` },
        //     { value: `${t('From')} 299.00` },
        //   ],
        // },
        // {
        //   title: `${t('Confirmed Screening')}`,
        //   subTitle: ``,
        //   tariff: [
        //     { value: `${t('From')} 180.00 (ab 10 Tickets)` },
        //     { value: `${t('From')} 80.00 (ab 5 Tickets)` },
        //   ],
        // },
        // {
        //   title: `${t('Rent your Stussihof')} 2h`,
        //   subTitle: `${t('(w/o movie)')}`,
        //   tariff: [{ value: `350.00` }, { value: `250.00` }],
        // },
        // {
        //   title: `${t('Rent your Stussihof')} 4h`,
        //   subTitle: `${t('(w/o movie)')}`,
        //   tariff: [{ value: `550.00` }, { value: `450.00` }],
        // },
        // {
        //   title: `${t('Rent your Stussihof')} 8h`,
        //   subTitle: `${t('(w/o movie)')}`,
        //   tariff: [{ value: `850.00` }, { value: `650.00` }],
        // },
        // {
        //   title: `${t('Saale Pigale')} 1h`,
        //   subTitle: ``,
        //   tariff: [{ value: `250.00` }, { value: `` }],
        // },
        // {
        //   title: `${t('Per Hour')}`,
        //   subTitle: ``,
        //   tariff: [{ value: `+100.00` }],
        // },
        // {
        //   title: `${t('Whole Day')}`,
        //   subTitle: ``,
        //   tariff: [{ value: `650.00` }],
        // },
      ],
    },
  ];
  return (
    <>
      <section className="tariffs_and_vouchers align_new">
        <div className="left_right_align">
          <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('Prices_text')} />
          <CenteredTitle firstHalf={t('Prices_text')} secondHalf="" />
        </div>
        <div className="row">
          <article className="col-lg-10 col-xl-8 mx-auto">
            <article className=" tariffs_and_vouchers_cards_wrapper">
              <>
                {/* <h6>
                {"Tariffs"}
            </h6> */}
              </>
            </article>
          </article>
        </div>

        <div
          className={`grid_wrapper tariff_table mb-0 ${
            isMobile ? 'flex-nowrap' : ''
          }`}
        >
          <div className="">
            <p className="text-primary mb-3">{t('Prices_text')}</p>
            <p className="mb-3">{t('Adult')}</p>
            <p className="">{t('Teenagers_new')}</p>
            <span className="subtitles">{t('Up_to_16_years')}</span>
            <p className="mt-3">{t('Children_ up_to_12')}</p>
          </div>

          <div className="text-center d-block">
            <p className="mb-3">{t('Regular_Show')}</p>
            <div className="price_btn mb-3">{t('17.00')}</div>
            <br />
            <div className="price_btn mb-3">{t('15.50')}</div>
            <br />
            <div className="price_btn mb-3">{t('15.00')}</div>
          </div>

          <div className="text-center">
            <p className="mb-3">{t('Kids_program')}</p>
            <div className="price_btn mb-3">{t('15.50')}</div>
            <br />
            <div className="price_btn mb-3">{t('15.50')}</div>
            <br />
            <div className="price_btn mb-3">{t('14.00')}</div>
          </div>
        </div>
        {/* TABLE */}
        {/* {tariffs1.map((tariff, index) => (
          <div className="tariff_table mb-0" key={index}>
            <table>
              <thead>
                <tr>
                  {tariff.header.map((head, headIndex) => (
                    <th key={headIndex}>
                      <h6>{head.title}</h6>
                      <p>{head.subTitle}</p>
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {tariff.body.map((data, dataindex) => (
                  <tr key={dataindex}>
                    <td>
                      <h6>{data.title}</h6>
                      <p>{data.subTitle}</p>
                    </td>
                    {data.tariff.map((t, tindex) => (
                      <td key={tindex}>
                        <div className={t.value && 'tariff_code'}>{t.value}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))} */}

        <hr style={{ backgroundColor: 'white', margin: '0px' }} />
        {/* {tariffs2.map((tariff, index) => (
          <div className="tariff_table" key={index}>
            <table>
              <tr>
                {tariff.header.map((head, headIndex) => (
                  <td
                    key={headIndex}
                    // style={{
                    //   width: !isMobile && headIndex == 1 ? '220px' : '200px',
                    // }}
                  >
                    <h6 style={{ textAlign: 'left' }}>{head.title}</h6>
                    <p>{head.subTitle}</p>
                  </td>
                ))}
              </tr>

              <tbody>
                {tariff.body.map((data, dataindex) => (
                  <tr key={dataindex}>
                    <td>
                      <h6>{data.title}</h6>
                      <p>{data.subTitle}</p>
                    </td>
                    {data.tariff.map((t, tindex) => (
                      <td
                        key={tindex}
                        style={{
                          textAlign: 'left',
                          // paddingLeft: isTablet ? '30px' : '40px',
                        }}
                      >
                        <div className={t.value && 'tariff_code'}>{t.value}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="tarrif_para px-0">
              <p className="tariff_highlight mb-3">
                <span style={{ color: 'red' }}>* </span>
                {t('Discounted_prices_of_cinema_tickets')}
              </p>
            </div>
          </div>
        ))} */}

        <div className="grid_wrapper tariff_table mb-0">
          <div className="">
            <p className="text-primary mb-3">{t('Cinema_tickets')}</p>
            <p className="mb-3">{t('Card_holders')}</p>
            <p className="">{t('First_accompaniment')}</p>

            <p className="mt-3">{t('Any_other_accompaniment')}</p>
            <span className="subtitles">{t('up_to_a_group')}</span>
          </div>

          <div className="text-center d-block">
            <p className="mb-3">{t('Each_show')}</p>
            <div className="price_btn mb-3">{t('14.50')}</div>
            <br />
            <div className="price_btn mb-3">{t('14.50')}</div>
            <br />
            <div className="price_btn mb-3">{t('15.50')}</div>
          </div>
        </div>

        <div className="tarrif_para">
          <p className="tariff_highlight mb-3">
            <span style={{ color: 'red' }}>* </span>
            {t('Discounted_prices_of_cinema_tickets')}
          </p>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(TariffsAndVouchers);
