import React from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const TnCNewContainer = ({ t }) => {
  /*  let trn  = {
    'PP.header01': `Data protection cinema Stüssihof`,
    'PP.content01': `Arena Cinemas attaches great importance to data protection and assures that the user's data will be treated confidentially in accordance with the applicable data protection regulations.
Under the titles and tools listed, you can see where and by whom data is collected and what it is used for.`,

    'PP.header02': `Secure data transmission`,
    'PP.content02': `Data transmissions over the Internet are generally associated with a certain risk. Arena Cinemas therefore works with a corresponding security certificate and encrypts all personal and order-related data via SSL encryption for maximum data security and privacy protection.`,

    'PP.header03': `Cookies`,
    'PP.content03': `A cookie is a text file that is stored on your hard drive or in your browser for a certain period of time when you visit a website or access a service. If you visit the same website again, the cookie tells the server that there was already a connection with this PC. The cookies we use are there to show you the correct local Arena page, for example Arena Sihlcity when you visit us from Zurich, or to show a film that matches your previous selection when you come back to the program.`,

    'PP.header04': `Consent email newsletter`,
    'PP.content04': `We obtain your consent to the sending of our newsletter when you register by means of a double opt-in. If you no longer wish to receive our newsletter, you can unsubscribe either in the subscribe/unsubscribe form or at the end of each newsletter. Based on Article 13 of the Swiss Federal Constitution and the data protection regulations of the federal government (Data Protection Act, DSG), every person has the right to protection of their privacy and protection against misuse of their personal data. We comply with these regulations. Personal data is treated as strictly confidential and is neither sold nor passed on to third parties. In close cooperation with our hosting providers, we strive to protect the databases as well as possible against unauthorized access, loss, misuse or falsification. When accessing our website, the following data is stored in log files: IP address, date, time, browser request and general information transmitted about the operating system or. browsers. This usage data forms the basis for statistical anonymous evaluations so that trends can be identified, which we can use to improve our offers accordingly.`,

    'PP.header05': `Disclaimer`,
    'PP.content05': `The author assumes no liability for the correctness, accuracy, timeliness, reliability and completeness of the information. Liability claims against the author for material or immaterial damage resulting from access to, use or non-use of the published information, misuse of the connection or technical faults are excluded. All offers are non-binding. Arena Cinemas expressly reserves the right to change, supplement or delete parts of the pages or the entire offer or to temporarily or permanently cease publication without prior notice.`,

    'PP.header06': `Liability for links`,
    'PP.content06': `References and links to third-party websites are outside our area of responsibility. It rejected any responsibility for such websites. Access and use of such websites is at the user's own risk.`,

    'PP.header07': `copyrights`,
    'PP.content07': `The copyright and all other rights to content, images, photos or other files on the website belong either to Arena Cinemas or, specifically in the case of film promotional material, to the studios or their distributors. The written permission of the copyright holders must be obtained in advance for the reproduction of any elements.`,

    'PP.header08': `Google Analytics`,
    'PP.content08': `Our website uses Google Analytics, a web analytics service provided by Google Inc. Google Analytics uses so-called "cookies". These are text files that are stored on your computer and that enable an analysis of your use of the website. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the USA and stored there. If IP anonymization is activated on this website, however, your IP address will be shortened beforehand by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be sent to a Google server in the USA and shortened there. Google will use this information to evaluate your use of the website, to compile reports on website activity for website operators and to provide other services related to website activity and internet usage. Google may also transfer this information to third parties if required to do so by law or if third parties process this data on behalf of Google. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data. You can prevent the installation of cookies by setting your browser software accordingly; we would like to point out to you however that in this case you can if applicable not use all functions of this website in full. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. We remind you that Arena Cinemas uses the anonymized version of Google Analytics.`,

    'PP.header09': `GoogleMyBusiness`,
    'PP.content09': `With GoogleMyBusiness we can publish our information in a way that is optimal for search engines. Google stores both information you enter for a search and information about the page you viewed when you clicked on GoogleMyBusiness combined pages. In addition to the purposes explained above, the information you provide will be used in accordance with the applicable Google data protection regulations ( http://www.google.com/intl/de/policies/privacy/ ). Google may publish aggregated statistics about user activity or share these statistics with our users and partners, such as publishers, advertisers or affiliated websites.`,

    'PP.header10': `Google Maps`,
    'PP.content10': `Arena Cinemas uses the Google Maps API, a map service provided by Google Inc., to display an interactive map. Google Maps is operated by Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, USA. By using Google Maps, information about your use of this website (including your IP address) can be transmitted to a Google server in the USA and stored there. Google may transfer the information obtained through Maps to third parties if this is required by law or if third parties process this data on behalf of Google. Under no circumstances will Google associate your IP address with other Google data. Nevertheless, it would be technically possible for Google to identify at least individual users based on the data received. It is possible that personal data and personality profiles of users of the Google website could be processed for other purposes over which we have and cannot have any influence. You have the option of deactivating the Google Maps service and thus preventing data transfer to Google by deactivating JavaScript in your browser. However, we would like to point out that in this case you will not be able to use the map display on our website.`,

    'PP.header11': `reCAPTCHA`,
    'PP.content11': `To protect your inquiries via the Internet form, we use the security check service reCAPTCHA from Google Inc. . The query serves to distinguish whether the input is made by a human or abusively by automated, machine processing. The query includes sending the IP address and any other data required by Google for the reCAPTCHA service to Google. For this purpose, your input will be transmitted to Google and used there. However, your IP address will be shortened beforehand by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be sent to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information to evaluate your use of this service. The IP address transmitted by your browser as part of reCaptcha will not be merged with other Google data. The deviating data protection regulations of the company Google apply to this data. You can find more information about Google's data protection guidelines at: https://www.google.com/intl/de/policies/privacy/`,

    'PP.header12': `Youtube`,
    'PP.content12': `Movie Trailers: Arena Cinemas movie trailers are embedded YouTube movies. As long as these are viewed as embedded, your IP will not be tracked. However, if the trailers are opened and viewed in a separate browser window, they are visible and trackable for YouTube.`,

    'PP.header13': `Facebook`,
    'PP.content13': `There are links to https://www.facebook.com/ArenaCinemasZurich on our website. Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA integrated. You can recognize the Facebook plugins by the Facebook logo or the “Like button” (“I like”) on our site. You can find an overview of the Facebook plugins here: http://developers.facebook.com/docs/plugins/. When you visit our pages, a direct connection is established between your browser and the Facebook server via the plugin. Facebook receives the information that you have visited our site with your IP address. If you click the Facebook "Like" button while you are logged into your Facebook account, you can link the content of our pages to your Facebook profile. This allows Facebook to associate your visit to our site with your user account. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the data transmitted or how it is used by Facebook. You can find more information on this in Facebook's privacy policy at https://www.facebook.com/about/privacy/ If you do not want Facebook to be able to associate your visit to our site with your Facebook user account, please log out of your Facebook user account.`,

    'PP.header14': `Twitter`,
    'PP.content14': `Functions of the Twitter service are integrated on our sites. These functions are offered by Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA 94107, USA. By using Twitter and the "Re-Tweet" function, the websites you visit are linked to your Twitter account and made known to other users. Data such as IP address, browser type, domains called up, pages visited, mobile phone provider, device and application IDs and search terms are transmitted to Twitter. We would like to point out that we, as the provider of the pages, have no knowledge of the content of the data transmitted or how it is used by Twitter. Due to the ongoing updating of Twitter's data protection declaration, we refer to the latest version at (http://twitter.com/privacy). You can change your privacy settings on Twitter in the account settings at http://twitter.com/account/settings. If you have any questions, contact  privacy@twitter.com .`,

    'PP.header15': `Instagram`,
    'PP.content15': `Instagram, https://www.instagram.com/arenacinemaszh/ is directly integrated on our website. This feature is offered by Instagram. Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA. If you are logged into your Instagram account, you can click on the Instagram button to link the content of our pages to your Instagram profile. This allows Instagram to associate your visit to our site with your user account. We would like to point out that as the provider of the pages, we have no knowledge of the content of the data transmitted or how it is used by Instagram. For more information, see Instagram's privacy policy: http://instagram.com/about/legal/privacy/.`,

    'PP.header16': `Sweepstakes or sweepstakes`,
    'PP.content16': `Arena Cinemas regularly advertises sweepstakes or sweepstakes. The resulting mail submissions will be deleted immediately after each competition or raffle. Submissions will not be collected and will not be shared with third parties. Except when Arena Cinemas is promoting a sweepstakes or sweepstakes for a film studio. In this case, this will be explicitly mentioned in the advertisement.`,

    'PP.header17': `Online ticket purchase`,
    'PP.content17': `If you have further questions about data protection on our website, require information or would like to request the deletion of your data, please send an e-mail to our contact person for data protection.`,

    'PP.header18': `The contact details of our data protection officer are as follows`,
    'PP.content18': `datenschutzbeauftragter@arena.ch`,

    }

  let array=[];
  Object.keys(trn).map(x=>{
    let obj={
      module: "common",
      category: "",
      key: x,
      en: trn[x],
      de: trn[x],
      fr: trn[x],
      it: trn[x],
      is_static: "Y",
    };
    array.push(obj)
  })
  console.log(array,"-------------------------------------------------------")

  let data = Array.from(Array(18).keys())*/

  return (
    <>
      <div className="tnc_new align_new">
        <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={`${t('Privacy Policy')}`}
          />
          <CenteredTitle firstHalf={`${t('Privacy Policy')}`} secondHalf={''} />
        </div>
        <section className="row">
          <article className="col-lg-10 col-xl-12 tnc_back">
            <h3>Privacy Policy Brij Cinemas Sihlcity</h3>
            <div>
            Brij Cinemas attaches great importance to data protection and assures that the user's
              data will be treated confidentially in accordance with the applicable data protection regulations.
            </div>
            <p>Under the titles and tools listed, you can see where and by whom data is collected and what it is used for.</p>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header01')} */}
              Secure data transmission
            </p>
            {/* <div>{t('PP.content01')}</div> */}
            <div>
              Data transmissions via the Internet always involve a certain risk. Brij Cinemas therefore works with a corresponding security certificate and encrypts all
              personal and order-related data via SSL encryption, for maximum data security and privacy protection.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header02')} */}
              cookies
            </p>
            <div>
              {/* {t('PP.content02')} */}
              A cookie is a text file that is stored on your hard drive or in your browser
              for a certain period of time when you visit a website or access a service.
              If you visit the same website again, the cookie tells the server that there
              was already a connection with this PC. The cookies we use are there to show you
              the correct local Brij page, for example Brij Sihlcity when you visit us from
              Zurich, or to show you
              a film that matches your previous selection when you come back to the program.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header03')} */}
              Consent email newsletter
            </p>
            <div>
              {/* {t('PP.content03')} */}
              We obtain your consent to the sending of our newsletter when you register by
              means of a double opt-in. If you no longer wish to receive our newsletter,
              you can unsubscribe either in the subscribe/unsubscribe form or at the end of each newsletter.
            </div>
            <div>
              Based on Article 13 of the Swiss Federal Constitution and the data protection regulations of the federal
              government (Data Protection Act, DSG), every person has the right to protection of their privacy and
              protection against misuse of their personal data. We comply with these regulations.
              Personal data is treated as strictly confidential and is neither sold nor passed on to third parties.
            </div>
            <div>
              In close cooperation with our hosting providers, we try to protect the databases as well as possible against unauthorized access, loss, misuse or falsification.
              When accessing our website, the following data is stored in log files: IP address, date, time, browser request and general information transmitted about the operating system or. browsers.
              This usage data forms the basis for statistical anonymous evaluations so that trends can be identified, which we can use to improve our offers accordingly.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header04')} */}
              Disclaimer
            </p>
            <div>
            The author assumes no liability for the correctness, accuracy, timeliness, reliability and completeness of the information.
Liability claims against the author for material or immaterial damage resulting from access to, use or non-use of the published information, misuse of the connection or technical faults are excluded.
            </div>
            <div>
            All offers are non-binding. Brij Cinemas expressly reserves the right to change, supplement or delete parts of the pages or the entire offer or to temporarily or permanently cease publication without prior notice.
            </div>
          
            {/* <div>{t('PP.content04')}</div> */}
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header05')} */}
              Liability for links
            </p>
            <div>
              {/* {t('PP.content05')} */}
              References and links to third-party websites are outside our area of ​​responsibility.
              It rejected any responsibility for such websites.
              Access and use of such websites is at the user's own risk.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header06')} */}
              Copyright
            </p>
            <div>
              {/* {t('PP.content06')} */}
              The copyright and all other rights to content, images, photos or other files
              on the website belong either to Brij Cinemas or, specifically in the case of
              film promotional material, to the studios or their distributors. The written
              permission of the copyright
              holders must be obtained in advance for the reproduction of any elements.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header07')} */}
              Google Analytics
            </p>
            <div>
              {/* {t('PP.content07')} */}
              Our website uses Google Analytics, a web analytics service provided by Google Inc.
              Google Analytics uses so-called "cookies". These are text files that are stored
              on your computer and that enable an analysis of your use of the website.
              The information generated by the cookie about your use of this website is
              usually transmitted to a Google server in the USA and stored there.
              If IP anonymization is activated on this website, however, your IP
              address will be shortened beforehand by Google within member states of the European Union or in
              other contracting states of the Agreement on the European Economic Area.
            </div>
            <div>
              Only in exceptional cases will the full IP address be sent to a Google server
              in the USA and shortened there. Google will use this information to evaluate
              your use of the website, to compile reports on website activity for website
              operators and to provide other services related to website activity and internet usage.
              Google may also transfer this information to third parties if required to do so by law or
              if third parties process this data on behalf of Google. The IP address transmitted by your browser
              as part of Google Analytics will not be merged with other Google data.
            </div>
            <div>
              You can prevent the installation of cookies by setting your browser software accordingly;
              we would like to point out to you however that in this case you can if applicable not use
              all functions of this website in full. By using this website, you consent to the
              processing of data about you by Google in the manner and for the purposes set out above.
            </div>
            <div>
            We remind you that Brij Cinemas uses the anonymized version of Google Analytics.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header08')} */}
              GoogleMyBusiness
            </p>
            <div>
              {/* {t('PP.content08')} */}
              With GoogleMyBusiness we can publish our information in a way that is optimal for search engines.
              <p>Google stores both information you enter for a search and information about the page you viewed
                when you clicked on GoogleMyBusiness combined pages. In addition to the purposes explained above,
                the information you provide will be
                used in accordance with the applicable Google data protection regulations (
              <a href="#" style={{ color: '#1f1c3c' }}>
              http://www.google.com/intl/de/policies/privacy/ 
                </a>
                ). Google may publish aggregated statistics about user activity or share these statistics with our users and partners,
                such as publishers, advertisers or affiliated websites.
              </p>
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header08')} */}
              Google Maps
            </p>
            <div>
            Brij Cinemas uses the Google Maps API, a map service provided by Google Inc.,
              to display an interactive map. Google Maps is operated by Google Inc.,
              1600 Amphitheater Parkway, Mountain View, CA 94043, USA.
            </div>
            <div>
              By using Google Maps, information about your use of this website (including your IP address)
              can be transmitted to a Google server in the USA and stored there.
              Google may transfer the information obtained through Maps to third parties if this
              is required by law or if third parties process this data on behalf of Google.
            </div>
            <div>
              Under no circumstances will Google associate your IP address with other Google data.
              Nevertheless, it would be technically possible for Google to identify at least
              individual users based on the data received. It is possible that personal data
              and personality profiles of users of the Google website could be processed for
              other purposes over which we have and cannot have any influence.
              You have the option of deactivating the Google Maps service and thus preventing
              data transfer to Google by deactivating JavaScript in your browser.
              However, we would like to point out that in this case you will not be able
              to use the map display on our website.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
              {/* {t('PP.header08')} */}
              eCAPTCHA _
            </p>
            <div>
              To protect your inquiries via the Internet form, we use the security check service reCAPTCHA
              from Google Inc. . The query serves to distinguish whether the input is made by a human or
              abusively by automated, machine processing. The query includes sending the IP address and
              any other data required by Google for the reCAPTCHA service to Google. For this purpose,
              your input will be transmitted to Google and used there. However, your IP address will be
              shortened beforehand by Google within member states of the European Union or in other contracting
              states of the Agreement on the European Economic Area. Only in exceptional cases will the full IP
              address be sent to a Google server in the USA and shortened there. On behalf of the operator of
              this website, Google will use this information to evaluate your use of this service.
              The IP address transmitted by your browser as part of reCaptcha will not be merged with other
              Google data. The deviating data protection regulations of the company Google apply to this data.
              You can find more information
              about Google's data protection guidelines at: https://www.google.com/intl/de/policies/privacy/
            </div>
            <p className="tnc_new_text_highlighted mb-2">
            Facebook
            </p>
            <div>
              There are links to https://www.facebook.com/ArenaCinemasZurich on our website.
              Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA integrated.
              You can recognize the Facebook plugins by the Facebook logo or the “Like button”
              (“I like”) on our site.
              You can find an overview of the Facebook plugins here: http://developers.facebook.com/docs/plugins/.
            </div>
            <div>
              When you visit our pages, a direct connection is established between your browser and the
              Facebook server via the plugin. Facebook receives the information that you have visited
              our site with your IP address. If you click the Facebook "Like" button while you are
              logged into your Facebook account, you can link the content of our pages to your Facebook profile.
              This allows Facebook to associate your visit to our site with your user account. We would like
              to point out that we, as the provider of the pages, have no knowledge of the content of the data transmitted or how it is used by Facebook. You can
              find more information on this in Facebook's privacy policy at https://www.facebook.com/about/privacy/
            </div>
            <div>
            If you do not want Facebook to be able to associate your visit to our site with your Facebook user account, please log out of your Facebook user account.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
            Instagram
            </p>
            <div>
              Instagram, https://www.instagram.com/arenacinemaszh/ is directly integrated into our website.
              This feature is offered by Instagram. Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025,
              USA. If you are logged into your Instagram account, you can click on the Instagram button to
              link the content of our pages to your Instagram profile. This allows Instagram to associate
              your visit to our site with your user account. We would like to point out that we, as the
              provider of the pages,
              have no knowledge of the content of the transmitted data or how it is used by Instagram.
            </div>
            <div>
            For more information, see Instagram's privacy policy: http://instagram.com/about/legal/privacy/.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
            Sweepstakes or sweepstakes
            </p>
            <div>
            Brij Cinemas regularly advertises sweepstakes or sweepstakes.
              The resulting mail submissions will be deleted immediately after each competition or raffle.
              Submissions will not be collected and will not be shared with third parties.
              Except when Brij Cinemas is promoting a sweepstakes or sweepstakes for a film studio.
              In this case, this will be explicitly mentioned in the advertisement.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
            Online ticket purchase Means of payment
            </p>
            <div>
              The Brij Cinemas hosted ticket sales system from the Munich company "Compeso" is linked
              directly to the Swiss payment provider "Datatrans". This means that your sensitive data
              is processed directly and only on the "Datatrans" payment system. Our online ticketing
              only receives the "Good for purchase" and the last 4 digits of your credit card in order
              to make cancellations on the Datatrans payment system if necessary. We do not have access
              to this data and we do NOT store any information about your credit card.
              This procedure is strictly checked
              annually by "Sixt Multipay" and Brij Cinemas is allowed to use the "Acertigo" rating.
            </div>
            <p className="tnc_new_text_highlighted mb-2">
            Cinema software and Brij Card
            </p>
            <div>
              In order to ensure successful online ticket sales, your email address and a password are
              required in order to be able to send you the purchase confirmation and the pick-up code.
              You have the choice of opening an brij account or making a one-time purchase.
              In the second case, the login is not saved. In the first case and if you have an Brij Card,
              Brij Cinemas will keep a customer account for you. This is stored by us until you wish to
              delete it. As an Brij Cinemas customer, you have the right to data
              information and the right to data deletion, which can be claimed online or at the counter.
            </div>
            <div>
              If you have further questions about data protection on our website, require information or would like to
              request the deletion of your data, please send an e-mail to our contact person for data protection.
            </div>
            <div>The contact details of our data protection officer are as follows:</div>
            <div>
            data protection officer `at` http://brijeurope.brij.tech
            </div>
            <p className="tnc_new_text_highlighted mb-2">
            Brij Cinemas
            </p>
            <p>Kalanderplatz 8</p>
            <p>8045 Zurich</p>
            <p>Switzerland</p>
            {/* <p className="tnc_new_text_highlighted mb-2">{t('PP.header09')}</p>
            <div>{t('PP.content09')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header10')}</p>
            <div>{t('PP.content10')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header11')}</p>
            <div>{t('PP.content11')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header12')}</p>
            <div>{t('PP.content12')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header13')}</p>
            <div>{t('PP.content13')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header14')}</p>
            <div>{t('PP.content14')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header15')}</p>
            <div>{t('PP.content15')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header16')}</p>
            <div>{t('PP.content16')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header17')}</p>
            <div>{t('PP.content17')}</div>
            <p className="tnc_new_text_highlighted mb-2">{t('PP.header18')}</p>
            <div>Yourcinema@yourcinema.com</div> */}
            {/* {t("PP.content18")} */}

            {/* <p className="tnc_new_text_highlighted mb-2">Your Address</p>

            <p className="tnc_new_text_highlighted mb-2 d-none">
              Stüssihofstatt 13
            </p>
            <p className="tnc_new_text_highlighted mb-2 d-none">CH-8001 Zürich</p> */}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
