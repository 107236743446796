import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Components
import { Accordion, Card } from 'react-bootstrap';
import CenteredTitle from '@components/partials/CenteredTitle';
import { useSelector } from 'react-redux';
import BreadCrumb from '@components/partials/BreadCrumbs';
import searchIcon from '@assets/pictures/svgs/search.svg';
import close from '@assets/pictures/svgs/close.svg';
import InlineScroll from '@components/partials/InlineScroll';
import useIsMobile from '../customHooks/useIsMobile';
const FAQNewContainer = ({ t }) => {
  const history = useHistory();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const isMobile = useIsMobile();
  const lengthOfFaqsArray = 10;
  const [activeAccordionTab, setActiveAccordionTab] = useState(
    [...new Array(lengthOfFaqsArray)].map((item, index) => (index ? false : true)),
  );
  const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState('0');
  const handleAccordionTab = (index) => {
    let newActiveAccordionTab = Array(lengthOfFaqsArray).fill(false);
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null);
      newActiveAccordionTab[index] = false;
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true;
    }
    setActiveAccordionTab(newActiveAccordionTab);
  };

  let [faqs, setFaqs] = useState([]);
  let [filteredFaq, setFilteredFaq] = useState([]);
  let [categories, setCategories] = useState([]);

  useEffect(() => {
    let faqs = [];
    let categories = new Set();

    for (let i = 1; i < 22; i++) {
      let faq = {
        q: t(`FAQ.Q${i}`),
        a: t(`FAQ.A${i}`),
        category: 'General',
        categoryDisplay: t('General'),
      };

      if (i >= 12 && i <= 16) {
        faq.category = 'On Demand';
        faq.categoryDisplay = t('On Demand');
      } else if (i >= 16 && i <= 21) {
        faq.category = 'About Cinema';
        faq.categoryDisplay = t('About Cinema');
      }

      faqs.push(faq);
      categories.add(
        JSON.stringify({
          category: faq.category,
          categoryDisplay: faq.categoryDisplay,
        }),
      );
    }

    setFaqs(faqs);
    setFilteredFaq(faqs);
    setCategories(Array.from(categories.map((c) => JSON.parse(c))));
  }, [selected_language]);

  let [search, setSearched] = useState('');
  let [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    let filteredBySearch = [...faqs];

    if (!!selectedCategory) {
      filteredBySearch = filteredBySearch.filter((f) => {
        return f.category == selectedCategory;
      });
    }

    if (!!search) {
      filteredBySearch = filteredBySearch.filter((f) => {
        if (f.q.toLowerCase().includes(search.toLowerCase())) return true;
        else if (f.a.toLowerCase().includes(search.toLowerCase())) return true;

        return false;
      });
    }

    setFilteredFaq(filteredBySearch);
  }, [faqs, search, selectedCategory]);

  return (
    <>
      <div className="container-fluid faq_new align_new">
        <section className="row">
          <div
            className="dsdss"
            style={{
              color: 'red',
              backgroundImage: `url(
                "data:image/svg+xml",
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="blue"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="13" cy="13" r="13" fill="green" />
                  <path
                    d="M7 11L13 17L19 11"
                    stroke="#D4307D"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )`,
            }}
          ></div>

          <div className={isMobile ? 'left_right_align' : ''}>
            <BreadCrumb firstHalf={`${t('More')} >`} secondHalf=" FAQ’s" />
            <p className="faq_title">Frequently Asked Questions</p>
            {/*<CenteredTitle firstHalf={t("Frequently Asked Questions")} secondHalf=""/>*/}
          </div>
          <article className="col-lg-10 col-xl-8 mx-auto">
            {/* <label className="fnb-search mb-4" style={{ width: '80%' }}>
              <img src={searchIcon} alt="search" onClick={(e) => {}} />
              <input
                type="text"
                placeholder={t('Search for concessions')}
                value={search}
                onChange={(e) => {
                  setSearched(e?.target?.value || '');
                }}
                style={{ borderRadius: '38px' }}
              />
              <img
                src={close}
                alt="close"
                className={`${!!search ? 'show' : ''}`}
                onClick={() => {
                  setSearched('');
                  setSelectedCategory('');
                }}
              />
            </label> */}
            <div>
              {/* <InlineScroll>
                {categories.map((c, i) => (
                  <button
                    key={i}
                    className={`faq_btn${
                      c.category === selectedCategory ? '' : '_inactive'
                    }`}
                    onClick={() => {
                      if (c.category === selectedCategory) setSelectedCategory('');
                      else setSelectedCategory(c.category);
                    }}
                  >
                    {c.categoryDisplay}
                  </button>
                ))}
              </InlineScroll> */}
            </div>
            <div>
              <Accordion className="faq_new_accordion ">
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <p>{t('faq_que1')}</p>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body>{t('faq_ans1')}</Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    {t('faq_data_title1')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      {t('Group_discount_part_one')}{' '}
                      <span
                        onClick={() => {
                          history.push('/contact');
                        }}
                        style={{
                          color: 'var(--primary)',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        {t('contact_title_new')}
                      </span>{' '}
                      {t('Group_discount_part_two')}{' '}
                      <span
                        onClick={() => {
                          history.push('/cinema-ticket');
                        }}
                        style={{
                          color: 'var(--primary)',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        {t('cinema_ticket')}
                      </span>{' '}
                      {t('Group_discount_part_three')}{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2">
                    {t('faq_que2')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="2">
                    <Card.Body>{t('faq_ans2')}</Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3">
                    {t('faq_data_que2_subtitle1')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="3">
                    <Card.Body>{t('faq_que2_subtitle1_data')}</Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="4">
                    {t('faq_data_que2_subtitle2')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      {t('faq_que2_subtitle2_data_para1')}
                      <p>{t('faq_que2_subtitle2_data_para2')}</p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="5">
                    {t('faq_que3')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="5">
                    <Card.Body>{t('faq_ans3')}</Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="6">
                    {t('faq_birthday_party_planing')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      {' '}
                      {t('PlanungFaq')}{' '}
                      <span
                        onClick={() => {
                          history.push('/contact');
                        }}
                        style={{
                          color: 'var(--primary)',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        {t('contact_title_new')}
                      </span>{' '}
                      {t('PlanungFaqSecontPart')}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="7">
                    {t('faq_que4')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      {t('We_can_do')}{' '}
                      <span
                        onClick={() => {
                          history.push('/rent-our-space');
                        }}
                        style={{
                          color: 'var(--primary)',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        {t('You_can_find')}
                      </span>{' '}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="8">
                    {t('faq_que6_cinepol')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="8">
                    <Card.Body>{t('faq_ans6_cinepol')}</Card.Body>
                  </Accordion.Collapse>
                </Card>

                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="9">
                    {t('faq_que7')}
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="9">
                    <Card.Body>
                      {t('The_poster_belong')}{' '}
                      <span
                        onClick={() => {
                          history.push('/contact');
                        }}
                        style={{
                          color: 'var(--primary)',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                      >
                        {t('contact_title_new')}
                      </span>{' '}
                      {t('The_poster_belong_second_part')}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(FAQNewContainer);
