import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import classImg from '@assets/pictures/classs_img.png';
const Agb = ({ t }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('agb_title_new')} />
        <CenteredTitle firstHalf={t('agb_title_new')} secondHalf={''} />
      </div>

      <div className="d-flex col-10 mx-auto">
        <div className="">
          <p className="mb-2 text-primary">{t('agb_text_our_website')}</p>
          <p className="mb-2 static_page">{t('agb_para1')}</p>
          <p className="mb-2 static_page">{t('agb_para2')}</p>
          <p className="text-primary mb-2">{t('agb_para3_title')}</p>
          <p className="mb-2 static_page">{t('agb_para3_data1')}</p>
          <p className="mb-2 static_page">{t('agb_para3_data2')}</p>
          <p className="mb-2 static_page">{t('agb_para3_data3')}</p>
          <p className="mb-2 static_page">{t('agb_data3_para4')}</p>
          <p className="mb-2 static_page">{t('agb_data3_para5')}</p>
          <p className="mb-2 static_page">{t('agb_data3_para6')}</p>
          <p className="mb-2 static_page">{t('agb_para3_data7')}</p>
        </div>
      </div>
    </>
  );
};

export default withNamespaces()(Agb);
