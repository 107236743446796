import React, { useState, useRef, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../customHooks/useIsMobile';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';

// Images
import openingHoursPattern from '@assets/pictures/openingHoursPattern.png';
import classImg from '@assets/pictures/classs_img.png';
const Vouchers = ({ t }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <div className="container-fluid align_new">
        <BreadCrumb
          firstHalf={`${t('More')} >`}
          secondHalf={t('Vouchers_title_new ')}
        />
        <CenteredTitle firstHalf={t('Vouchers_title_new ')} secondHalf={''} />
      </div>

      <div className="container-fluid opening_hours">
        <section className="row">
          <article className="col-12 mx-auto">
            <p className="mb-2">{t('vouchers_data_para1')}</p>
            <p className="mb-2">{t('vouchers_data_para2')}</p>
            <p className="mb-2">{t('vouchers_data_para3')}</p>
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Vouchers);
